import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import styles from './Belt.module.scss';

import list from '../../specialList';

const Posts = () => {

    const last4sp = useMemo(() => list.slice(0, 4), []);

    return (
        <div className={styles.wrap}>
            <h2>SPECIALS</h2>
            <div className={styles.row}>
                {
                    last4sp.map((row) => (
                        <Link to={`/special/${row.id}`} className={styles.cateWrap} title={row.id} key={row.id}>
                            <img src={row.cover} alt="2022 CARRYON 圖輯" loading="lazy" />
                        </Link>
                    ))
                }
            </div>
        </div>
    )

}

export default React.memo(Posts);
