import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';

// import { useMediaQuery } from 'react-responsive';

import SlideUp from '../SlideUp';

import utility from '../../utility';


import styles from './Products.module.scss';

import list from '../../onSaleProductList';


const Products = () => {

    console.log(list);

    return (
        <>
            {
                list.map(({ name, subTitle, list }) => (
                    <div className={styles.wrap} key={name}>
                        <h2>
                            {name}
                            {
                                subTitle
                                    ? <span className={styles.subTitle}>{subTitle}</span>
                                    : null
                            }
                        </h2>
                        <div className={utility.mixClasses(styles.row, styles.prodWrap)} >
                            {
                                [].concat(list.filter((data) => data.inStock)).concat(list.filter((data) => !data.inStock))
                                    .map(({ isNew, series, sku, caption, image, spec, type, price, inStock, specialOffer }, idx) => (
                                        <div key={sku} className={styles.prod}>
                                            <SlideUp delayOffset={idx}>
                                                <Link to={`/product/${sku}${utility.const.product.pathOperator}${caption.replace(utility.const.product.pathRegExp, utility.const.product.pathReplaceChar)}`} className={utility.mixClasses('', '')}>
                                                    {
                                                        isNew
                                                            ? (
                                                                <>
                                                                    <div className={styles.imgWrap} >
                                                                        <img src={encodeURI(`https://assets.carryon-clothing.com/${series}/product/${sku}/1.png`)} alt={caption} width="200" height="200" loading="lazy" />
                                                                        {
                                                                            !inStock
                                                                                ? <div className={styles.stock}>售完</div>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    <div className={styles.caption}>{caption}</div>
                                                                    <div className={styles.sub}>
                                                                        {/* <div className={styles.spec}>{spec}</div> */}
                                                                        <div className={utility.mixClasses('jso-price', styles.price)}>
                                                                            <span className={specialOffer && styles.specialOfferLineThrough}>
                                                                                {utility.priceWithCommas(price)}
                                                                            </span>
                                                                            {
                                                                                specialOffer && (
                                                                                    <span className={styles.specialOffer}>
                                                                                        {utility.priceWithCommas(specialOffer)}
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                            : (
                                                                <>
                                                                    <div className={styles.imgWrap} >
                                                                        <img src={type === 2 ? image : `${image}--sm.webp`} alt={caption} width="200" height="200" loading="lazy" />
                                                                        {
                                                                            !inStock
                                                                                ? <div className={styles.stock}>售完</div>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    <div className={styles.caption}>{caption}</div>
                                                                    <div className={styles.sub}>
                                                                        {/* <div className={styles.spec}>{spec}</div> */}
                                                                        <div className={utility.mixClasses('jso-price', styles.price)}>
                                                                            <span className={specialOffer && styles.specialOfferLineThrough}>
                                                                                {utility.priceWithCommas(price)}
                                                                            </span>
                                                                            {
                                                                                specialOffer && (
                                                                                    <span className={styles.specialOffer}>
                                                                                        {utility.priceWithCommas(specialOffer)}
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                    }

                                                </Link>
                                            </SlideUp>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                ))
            }
        </>
    )

}

export default React.memo(Products);
