import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../components/Layout';
// import Animate from '../components/common/Animate';

import styles from './NotFound.module.scss';

const NotFound = () => (
    <Layout footer={false}>
        <div className={styles.wrap}>
            <div className={styles.row}>
                <h1>頁面錯誤</h1>
                <p>您可點選下方按鈕回首頁</p>
                <Link to="/" className={styles.back} aria-label="回首頁">回首頁</Link>
            </div>
        </div>
    </Layout>
);

export default NotFound;
