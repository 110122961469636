import React from 'react';
import { useParams } from 'react-router-dom';
// import { Link } from 'react-router-dom';

import MetaTag from '../components/MetaTag';

// import { nanoid } from 'nanoid';

import NotFound from './NotFound';

import Layout from '../components/Layout';
import utility from '../utility'
import styles from './Product.module.scss';

import productList from '../productList';
import onSaleProductList from '../onSaleProductList';

import Carousel from '../components/product/Carousel';

const legacySeries = [
    'CARRYON 22FW',
    'CARRYON X FOREST',
    'CARRYON 22SS August',
    'CARRYON 22SS',
    'CARRYON 21FW',
    'CARRYON 21SS',
]

const Product = () => {

    const { slug } = useParams();

    try {

        const prods = [...productList.reduce((a, b) => a.concat(b.list), [])]
            .map((prod) => {

                let newProd = prod;

                onSaleProductList.forEach((item) => {

                    if (item.list.length > 0) {

                        item.list.forEach((onSaleProd) => {

                            if (onSaleProd.sku === prod.sku) {

                                newProd = onSaleProd;

                            }

                        });

                    }

                })

                return newProd;

            })


        const sku = slug.split(utility.const.product.pathOperator).shift();
        const { comingSoon, caption, image, series, spec, specImg, hasSpecImg, price, desc, prodDescImgs, prodImgLength, descImgs, descImgLength, fittingDesc, buyLink, inStock, specialOffer } = prods.find((item) => item.sku === sku);

        const url = `https://www.carryon-clothing.com/product/${sku}${utility.const.product.pathOperator}${caption.replace(utility.const.product.pathRegExp, utility.const.product.pathReplaceChar)}`;

        const isNew = !!series && !legacySeries.includes(series);


        return (
            <>
                <MetaTag
                    title={caption}
                    url={url}
                    image={
                        isNew
                            ? `https://assets.carryon-clothing.com/${series}/product/${sku}/1.png`
                            : `https://www.carryon-clothing.com/${image}--md.webp`
                    }
                />
                <Layout>
                    <section className={styles.wrap}>
                        <div className={styles.row}>
                            {
                                isNew
                                    ? (
                                        <Carousel
                                            imgList={
                                                Array.apply(null, Array(prodImgLength)).map((v, idx) => `https://assets.carryon-clothing.com/${series}/product/${sku}/${idx + 1}.png`)
                                            }
                                            caption={caption}
                                        />

                                    )
                                    : (
                                        <picture>
                                            <source srcSet={`${image}--md.webp`} media={utility.const.mediaBreakpoint.sm.query} />
                                            <img src={`${image}--md.webp`} alt={caption} width="400" height="400" loading="lazy" />
                                        </picture>
                                    )
                            }
                        </div>
                        <h1>{caption}</h1>
                        {/* <h2>{sku}</h2> */}
                        <div className={styles.row}>
                            <div className={utility.mixClasses('jso-price', styles.price)}>
                                <span className={specialOffer && styles.specialOfferLineThrough}>
                                    {utility.priceWithCommas(price)}
                                </span>
                                {
                                    specialOffer && (
                                        <span className={styles.specialOffer}>
                                            {utility.priceWithCommas(specialOffer)}
                                        </span>
                                    )
                                }
                            </div>
                        </div>
                    </section>
                    <section className={styles.wrap}>
                        <div className={styles.row}>
                            {
                                comingSoon 
                                    ? (
                                        <div className={utility.mixClasses(styles.placeOrderBtn, styles.btnDisabled)}>Coming Soon</div>
                                    ) : inStock
                                        ? (
                                            <a href={buyLink} className={styles.placeOrderBtn} target="_blank" rel="noreferrer noopener">下單</a>
                                        )
                                        : (
                                            <div className={utility.mixClasses(styles.placeOrderBtn, styles.btnDisabled)}>Sold Out</div>
                                        )
                            }
                            <a href="/buying-guide" className={styles.hint} target="_blank">第一次來嗎？點擊看購物指南。</a>
                        </div>
                    </section>
                    <section className={styles.wrap}>
                        {/* <section className={utility.mixClasses(styles.row, styles.desc, styles.detail)} >
                            {
                                prodDescImgs.map((imgSrc) => (
                                    <img key={nanoid()} src={`${imgSrc}`} alt={`${caption}細節`} width="600" height="600" loading="lazy" />
                                ))
                            }
                        </section> */}
                        <section className={utility.mixClasses(styles.row, styles.desc)} >
                            <h3>SIZE</h3>
                            <p>{spec}</p>
                            {
                                isNew
                                    ? specImg
                                        ? (
                                            <img
                                                src={specImg}
                                                alt="尺寸表"
                                                loading="lazy"
                                            />
                                        )
                                        : (
                                            spec === 'One Size' || spec === 'O/S'
                                                ? null
                                                : (
                                                    <img
                                                        src={`https://assets.carryon-clothing.com/${series}/product/${sku}/size.png`}
                                                        alt="尺寸表"
                                                        loading="lazy"
                                                    />
                                                )
                                        )
                                    : (
                                        specImg && <img
                                            src={specImg}
                                            alt="尺寸表"
                                            loading="lazy"
                                        />
                                    )

                            }
                        </section>
                        <section className={utility.mixClasses(styles.row, styles.desc)} >
                            {
                                fittingDesc.length > 0 && <h3>FITTING INFORMATION</h3>
                            }
                            {
                                fittingDesc.map((model, i) => (
                                    <ul key={`${model.toString()}_${i}`}>
                                        {
                                            model.map((modelDesc) => (<li key={modelDesc}>{modelDesc}</li>))
                                        }
                                    </ul>
                                ))
                            }
                        </section>
                        <section className={utility.mixClasses(styles.row, styles.desc)} >
                            <h3>DESCRIPTION</h3>
                            {
                                desc.replace(/(?:\\[rn]|[\r\n]+)+/g, '{SPLIT_POINT}').split('{SPLIT_POINT}')
                                    .map((t, idx) => (
                                        <p key={`${t}_${idx}`} dangerouslySetInnerHTML={{ __html: (t) }} />
                                    ))
                            }
                            {/* {
                                isNew && <img src={`/image/product/${series}/main/${sku}/${sku}-1.png`} alt={`${caption}細節`} width="600" height="600" loading="lazy" />
                            } */}
                            {/* {
                                prodDescImgs.map((imgSrc) => (
                                    <img key={`${imgSrc}_${caption}`} src={`${imgSrc}`} alt={`${caption}細節`} width="600" height="600" loading="lazy" />
                                ))
                            } */}
                            {
                                isNew
                                    ? (
                                        Array.apply(null, Array(descImgLength)).map((v, idx) => (
                                            <img key={idx} src={`https://assets.carryon-clothing.com/${series}/product/${sku}/inner/${idx + 1}.png`} alt={`${caption}穿搭`} width="600" height="600" loading="lazy" />
                                        ))
                                    )
                                    : (
                                        descImgs.map((imgSrc) => (
                                            <img key={`${imgSrc}_${caption}`} src={`${imgSrc}`} alt={`${caption}穿搭`} width="600" height="600" loading="lazy" />
                                        ))
                                    )
                            }
                        </section>
                    </section>
                </Layout>
            </>
        )

    }
    catch (e) {

        console.log(e);
        return (
            <NotFound />
        )

    }

};
export default React.memo(Product);
