import constants from './const';
import tool from './tool';

const env = process.env.NODE_ENV;

const utility = {
    ...tool,
    const: constants,
    env
};

export default utility;
