/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';

import utility from '../utility';

import styles from './Header.module.scss';

// const initialState = {
//     menu1: true,
//     menu2: true,
// };

// function reducer (state, action) {

//     const newState = { ...state };

//     newState[action.type] = !newState[action.type];

//     return newState;

// }

const Header = () => {

    const isMobile = useMediaQuery(utility.const.mediaBreakpoint.sm);

    const [showMenu, setShowMenu] = useState(false);

    // const [menuDisplay, dispatch] = useReducer(reducer, initialState);

    const [shadow, setShadow] = useState(false);

    const checkScroll = () => {

        setShadow(window.pageYOffset > 10);

    };

    useEffect(() => {

        window.addEventListener('scroll', checkScroll);

        return () => window.removeEventListener('scroll', checkScroll);

    }, [isMobile]);

    const triggerMenu = (e) => {

        e.preventDefault();

        setShowMenu(!showMenu);

    };

    const closeMenu = (e) => {

        e.preventDefault();

        setShowMenu(false);

    };

    return (
        <>
            <header className={utility.mixClasses(styles.wrap, shadow ? styles.withShadow : null)}>
                <div className={styles.row}>
                    <div className={styles.leftBox}></div>
                    <Link to="/" className={styles.logo} aria-label="Logo">
                        <img src="/image/carryon_logo-02.webp" className={styles.img} alt="Logo" />
                    </Link>
                    <div className={styles.rightBox}>
                        <a href="" className={styles.hamburger} aria-label="打開選單" onClick={triggerMenu}>
                            <i className="icon icon-menu"></i>
                        </a>
                    </div>
                </div>
            </header>
            {
                showMenu
                    ? (
                        <div className={styles.menuWrap}>
                            <a href="" className={styles.bg} onClick={closeMenu} />
                            <div className={styles.menuBlock}>
                                <div className={styles.menuCtrl}>
                                    <a href="" onClick={closeMenu}>
                                        <i className="icon icon-x"></i>
                                    </a>
                                </div>
                                <ul>
                                    <li>
                                        <div className={styles.item}>
                                            <Link to="/about-us" aria-label="About Us">About Us</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.item}>
                                            <Link to="/buying-guide" aria-label="Buying Guide">Buying Guide</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.item}>
                                            <Link to="/shipping-and-returns" aria-label="Shipping & Returns">Shipping & Returns</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.item}>
                                            <Link to="/privacy-and-terms" aria-label="Privacy & Terms">Privacy & Terms</Link>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <div className={styles.item}>
                                            <Link to="/" aria-label="Contact Us">Contact Us</Link>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    )
                    : null
            }
        </>
    );

};

export default Header;
