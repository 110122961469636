import React from 'react';
import PropTypes from 'prop-types';

import { Carousel } from 'react-responsive-carousel';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';

import { useMediaQuery } from 'react-responsive';



// import useFetch from '../../hooks/useFetch';

import utility from '../../utility';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import 'video-react/dist/video-react.css'; 

import styles from './BannerCarousel.module.scss';

import { bannerType, bannerList } from '../../bannerList';


const ImageBanner = ({ data }) => {

    const isMobile = useMediaQuery(utility.const.mediaBreakpoint.sm);

    return (
        <a className={styles.imgBanner} href={data.link} title={data.caption} aria-label={data.caption}>
            <picture>
                <source srcSet={data.imgUrlMobile} media={utility.const.mediaBreakpoint.sm.query} />
                <img src={data.imgUrlDesktop} alt={data.caption} />
            </picture>
            {
                !isMobile
                    ? (
                        <div className={styles.bannerInfo}>
                            <h2 style={{ color: data.captionColor }}>{data.caption}</h2>
                            <p style={{ color: data.contentColor }}>{data.content}</p>
                            {
                                !!data.buttonText
                                    ? (
                                        <span>{data.buttonText}</span>
                                    )
                                    : null
                            }
                        </div>
                    )
                    : null
            }
        </a>
    );

};

ImageBanner.propTypes = {
    data: PropTypes.shape({
        link: PropTypes.string,
        imgUrlMobile: PropTypes.string.isRequired,
        imgUrlDesktop: PropTypes.string.isRequired,
        caption: PropTypes.string,
        captionColor: PropTypes.string,
        content: PropTypes.string,
        contentColor: PropTypes.string,
        buttonText: PropTypes.string,
    }).isRequired
};



const YoutubeBanner = ({ data }) => {

    // const isMobile = useMediaQuery(utility.const.mediaBreakpoint.sm);

    return (
        <div className={styles.youtubeWrap}
            title={data.caption}
            aria-label={data.caption}
            style={{
                paddingBottom: `${data.ratio * 100}%`
            }}
        >
            <YouTube
                videoId={data.videoID}
                opts={{
                    playerVars: {
                      // https://developers.google.com/youtube/player_parameters
                      autoplay: 1,
                      playsinline: 1,
                      loop: 1,
                      playlist: data.videoID,
                      modestbranding: 1,
                      disablekb: 1,
                      mute: 1,
                      controls: 0,
                    },
                }}
                onReady={(e) => e.target.playVideo()}
            />
        </div>
    );

};

const VideoBanner = ({ data }) => {

     // const isMobile = useMediaQuery(utility.const.mediaBreakpoint.sm);

     return (
        <div className={styles.videoWrap} title={data.caption} aria-label={data.caption}>
            <ReactPlayer
                width="100%"
                height="100%"
                playing
                autoPlay
                muted
                playsinline
                loop
                url={data.src}
            />
        </div>
    );

}

const Banner = ({ data }) => {

    let banner;

    switch(data.bannerType) {

        case bannerType.youtube:
            banner = <YoutubeBanner data={data} />
            break;
        case bannerType.video:
            banner = <VideoBanner data={data} />
            break;
        case bannerType.image:
        default:
            banner = <ImageBanner data={data} />

    }

    return banner;

}

const BannerCarousel = () => {

    return (
        <div className={styles.wrap}>
            <div className={styles.row}>
                {
                    bannerList.length === 1
                        ? (
                            <Banner data={bannerList[0]} />
                        )
                        : (
                            <Carousel
                                showThumbs={false}
                                showStatus={false}
                                autoPlay
                                infiniteLoop
                                interval={10000}
                            >
                                {
                                    bannerList.map((bannerData) => (
                                        <Banner key={bannerData.src} data={bannerData} />
                                    ))
                                }
                            </Carousel>
                        )
                }

            </div>
        </div>
    );

};

export default React.memo(BannerCarousel);
