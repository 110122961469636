/* eslint-disable import/no-anonymous-default-export */
export default {
    mediaBreakpoint: {
        sm: { query: '(max-width: 640px)' },
        // md: { query: '(max-width: 768px)' },
        lg: { query: '(max-width: 1024px)' },
        xl: { query: '(max-width: 1280px)' },
        formSm: { query: '(max-width: 940px)' }
    },
    product: {
        pathOperator: '--',
        pathRegExp: /( |\/)/g,
        pathReplaceChar: '-'
    }
};
