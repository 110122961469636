import React, { useRef } from 'react';

import MetaTag from '../components/MetaTag';

import Layout from '../components/Layout';

import BannerCarousel from '../components/index/BannerCarousel';
import NewProducts from '../components/index/NewProducts';
import HotProducts from '../components/index/HotProducts';
// import Products from '../components/index/Products';
import OnSaleProducts from '../components/index/OnSaleProducts';


import Posts from '../components/index/Posts';
import Belt from '../components/index/Belt';

import utility from '../utility'

import styles from './Index.module.scss';

const Index = () => {

    return (
        <>
            <MetaTag />
            <Layout>
                <section className={styles.section} style={{ /*backgroundColor: '#FEFAF5'*/ }}>
                    <BannerCarousel></BannerCarousel>
                </section>
                <section className={utility.mixClasses(styles.section, styles.productWrap, styles.firstWrap)}>
                    <NewProducts index={0}></NewProducts>
                </section>
                <section className={utility.mixClasses(styles.section, styles.productWrap)}>
                    <HotProducts></HotProducts>
                </section>
                {/* <section className={utility.mixClasses(styles.section, styles.productWrap)}>
                    <NewProducts index={2}></NewProducts>
                </section> */}
                {/* <section className={utility.mixClasses(styles.section, styles.productWrap)}>
                    <NewProducts index={3}></NewProducts>
                </section> */}
                
                {/* <section className={utility.mixClasses(styles.section, styles.productWrap)}>
                    <Products index={1}></Products>
                </section> */}
                <section className={utility.mixClasses(styles.section, styles.productWrap)}>
                    <OnSaleProducts></OnSaleProducts>
                </section>
                <section>
                    {/* <div className={styles.imgWrap} >
                        <img src="/image/banner/2022-8.webp" alt="2022 CARRYON 圖輯" loading="lazy" />
                    </div> */}
                    <div className={styles.imgWrap} >
                        <img src="/image/product/CARRYON 23SS/sub-banner.png" alt="2022 CARRYON 圖輯" loading="lazy" />
                    </div>
                </section>
                <section className={styles.section}>
                    <Belt></Belt>
                </section>
                <section className={styles.section}>
                    <Posts></Posts>
                </section>
            </Layout>
        </>
    );

}

export default React.memo(Index);
