const onSale = [
    {
        name: 'UP TO 20% OFF',
        subTitle: '',
        list: [
            {
                isNew:true,
                sku: 'CARRYONLABTEE-GY',
                caption: 'CARRYON LAB 23SS 雀斑愛心人 寬短版上衣 水洗 灰色',
                spec: 'S-M',
                specImg: '',
                buyLink: 'https://forms.gle/AFHCCN61QnpVhrWa9',
                price: 980,
                specialOffer: 780,
                inStock: true,
                image: '',
                series: '23SS LAB',
                fittingDesc: [
                    ['Female', 'Height：159cm', 'Weight：44kg', 'Size：S'],
                    ['Female', 'Height：166cm', 'Weight：46kg', 'Size：M'],
                    ['Female', 'Height：160cm', 'Weight：47kg', 'Size：S'],
                    ['Female', 'Height：161cm', 'Weight：45kg', 'Size：M'],
                ],
                desc: `CARRYON LAB 23SS 雀斑愛心人 寬短版上衣

                ▲ 配色：灰色、紫羅蘭、淺紅
                ▲ 尺寸：S、M
                
                夏日女孩必備單品
                跟以往款式不同
                
                採用寬鬆短版剪裁
                讓整體穿搭更顯俐落帥氣
                搭配繽紛配色及水洗工法更加分
                
                設計上的雀斑愛心人
                提醒每位女孩
                堅強又可愛是妳的專利
                努力生活的同時也記得堅持自己的少女心！
                
                此款衣長較短
                可以穿著運動小可愛搭配
                
                Made In Taiwan
                
                【注意事項】欲購買請填寫表單，後續將有客服與您聯絡，確認已匯款金額
                【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主
                【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主`,
                prodImgLength: 4,
                descImgLength: 4,
                hasSpecImg: true
            },
            {
                isNew:true,
                sku: 'CARRYONLABTEE-RD',
                caption: 'CARRYON LAB 23SS 雀斑愛心人 寬短版上衣 水洗 淺紅',
                spec: 'S-M',
                specImg: '',
                buyLink: 'https://forms.gle/AFHCCN61QnpVhrWa9',
                price: 980,
                specialOffer: 780,
                inStock: true,
                image: '',
                series: '23SS LAB',
                fittingDesc: [
                    ['Female', 'Height：159cm', 'Weight：44kg', 'Size：S'],
                    ['Female', 'Height：166cm', 'Weight：46kg', 'Size：M'],
                    ['Female', 'Height：160cm', 'Weight：47kg', 'Size：S'],
                    ['Female', 'Height：161cm', 'Weight：45kg', 'Size：M'],
                ],
                desc: `CARRYON LAB 23SS 雀斑愛心人 寬短版上衣

                ▲ 配色：灰色、紫羅蘭、淺紅
                ▲ 尺寸：S、M
                
                夏日女孩必備單品
                跟以往款式不同
                
                採用寬鬆短版剪裁
                讓整體穿搭更顯俐落帥氣
                搭配繽紛配色及水洗工法更加分
                
                設計上的雀斑愛心人
                提醒每位女孩
                堅強又可愛是妳的專利
                努力生活的同時也記得堅持自己的少女心！
                
                此款衣長較短
                可以穿著運動小可愛搭配
                
                Made In Taiwan
                
                【注意事項】欲購買請填寫表單，後續將有客服與您聯絡，確認已匯款金額
                【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主
                【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主`,
                prodImgLength: 4,
                descImgLength: 4,
                hasSpecImg: true
            },
            {
                isNew:true,
                sku: 'CARRYONLABTANK-BK',
                caption: 'CARRYON LAB 23SS 雀斑愛心人 羅紋無袖短版背心  黑色',
                spec: 'L-XL',
                specImg: '',
                buyLink: 'https://forms.gle/AFHCCN61QnpVhrWa9',
                price: 880,
                specialOffer: 700,
                inStock: false,
                image: '',
                series: '23SS LAB',
                fittingDesc: [
                    ['Female', 'Height：159cm', 'Weight：44kg', 'Size：L'],
                    ['Female', 'Height：166cm', 'Weight：46kg', 'Size：XL'],
                    ['Female', 'Height：160cm', 'Weight：47kg', 'Size：L'],
                    ['Female', 'Height：161cm', 'Weight：45kg', 'Size：XL'],
                ],
                desc: `CARRYON LAB 23SS 雀斑愛心人 羅紋無袖短版背心 

                ▲ 配色：黑色、白色
                ▲ 尺寸：L、XL
                
                高溫度，需要來件無袖背心
                穿上直接變身夏季辣妹
                
                使用羅紋布料
                親膚感十足，炎炎夏日也不悶熱
                在搭配上能展現出甜美酷帥風格
                
                設計上的雀斑愛心人
                提醒每位女孩
                堅強又可愛是妳的專利
                努力生活的同時也記得堅持自己的少女心
                
                Made In Taiwan
                
                【注意事項】欲購買請填寫表單，後續將有客服與您聯絡，確認已匯款金額
                【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主
                【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主`,
                prodImgLength: 4,
                descImgLength: 4,
                hasSpecImg: true
            },
            {
                isNew:true,
                sku: 'CARRYONLABTANK-WH',
                caption: 'CARRYON LAB 23SS 雀斑愛心人 羅紋無袖短版背心  白色',
                spec: 'L-XL',
                specImg: '',
                buyLink: 'https://forms.gle/AFHCCN61QnpVhrWa9',
                price: 880,
                specialOffer: 700,
                inStock: false,
                image: '',
                series: '23SS LAB',
                fittingDesc: [
                    ['Female', 'Height：159cm', 'Weight：44kg', 'Size：L'],
                    ['Female', 'Height：166cm', 'Weight：46kg', 'Size：XL'],
                    ['Female', 'Height：160cm', 'Weight：47kg', 'Size：L'],
                    ['Female', 'Height：161cm', 'Weight：45kg', 'Size：XL'],
                ],
                desc: `CARRYON LAB 23SS 雀斑愛心人 羅紋無袖短版背心 

                ▲ 配色：黑色、白色
                ▲ 尺寸：L、XL
                
                高溫度，需要來件無袖背心
                穿上直接變身夏季辣妹
                
                使用羅紋布料
                親膚感十足，炎炎夏日也不悶熱
                在搭配上能展現出甜美酷帥風格
                
                設計上的雀斑愛心人
                提醒每位女孩
                堅強又可愛是妳的專利
                努力生活的同時也記得堅持自己的少女心
                
                Made In Taiwan
                
                【注意事項】欲購買請填寫表單，後續將有客服與您聯絡，確認已匯款金額
                【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主
                【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主`,
                prodImgLength: 4,
                descImgLength: 4,
                hasSpecImg: true
            },
            // {
            //     sku: '22FW hoodie-BK1',
            //     caption: 'CARRYON 22FW Energy Hoodie 閃電 連帽上衣 黑',
            //     spec: 'L-2XL',
            //     specImg:
            //         '/image/product/2023-01/size/CARRYON 22FW Energy Hoodie 閃電 連帽上衣.webp',
            //     buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
            //     price: 1680,
            //     specialOffer: 1340,
            //     inStock: true,
            //     image: '/image/product/2023-01/hoodie_bk1',
            //     fittingDesc: [
            //         ['Female', 'Height：159cm', 'Weight：44kg', 'Size：L', '寬鬆'],
            //         ['Male', 'Height：174cm', 'Weight：63kg', 'Size：XL', '寬鬆'],
            //     ],
            //     desc: `CARRYON 22FW Energy Hoodie 閃電 連帽上衣 黑
        
            //     推出首次獨特版型，寬鬆剪裁、無口袋無抽繩、下擺縮口羅紋材質縮短，袖口細節處置入全新織標，版型俐落乾淨，以舒適面料為底，觸感柔軟不起毛球，專注生活穿搭實用性，為薄款連帽上衣，可穿著運動、逛街等，外面可多層式搭配，不過於太悶熱！具備適度份量感
                
            //     胸前藍色閃電象徵「能量」
            //     提倡大家累了該好好休息
            //     為自己充電，再充滿電的下一次 
            //     重新帶著這份的能量 繼續發熱吧！
                
            //     【注意事項】確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨
                
            //     【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主
                
            //     【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主`,
            //     prodDescImgs: [
            //         // '/image/product/2021-5/2021hoodie-BK-1--d--1--md.webp',
            //         // '/image/product/2021-5/2021hoodie-BK-1--d--2--md.webp',
            //     ],
            //     descImgs: [
            //         '/image/product/2023-01/desc/CARRYON 22FW 連帽上衣 黑/1.png',
            //         '/image/product/2023-01/desc/CARRYON 22FW 連帽上衣 黑/2.png',
            //         '/image/product/2023-01/desc/CARRYON 22FW 連帽上衣 黑/3.png',
            //         '/image/product/2023-01/desc/CARRYON 22FW 連帽上衣 黑/4.png',
            //         '/image/product/2023-01/desc/CARRYON 22FW 連帽上衣 黑/5.png',
            //     ],
            // },
            {
                sku: '22FW hoodie-GY1',
                caption: 'CARRYON 22FW Energy Hoodie 閃電 連帽上衣 淺灰',
                spec: 'L-2XL',
                specImg:
                    '/image/product/2023-01/size/CARRYON 22FW Energy Hoodie 閃電 連帽上衣.webp',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 1680,
                specialOffer: 1340,
                inStock: false,
                image: '/image/product/2023-01/hoodie_gynew1',
                fittingDesc: [
                    ['Female', 'Height：159cm', 'Weight：44kg', 'Size：L', '寬鬆'],
                    ['Male', 'Height：174cm', 'Weight：63kg', 'Size：XL', '寬鬆'],
                ],
                desc: `CARRYON 22FW Energy Hoodie 閃電 連帽上衣 淺灰
        
                推出首次獨特版型，寬鬆剪裁、無口袋無抽繩、下擺縮口羅紋材質縮短，袖口細節處置入全新織標，版型俐落乾淨，以舒適面料為底，觸感柔軟不起毛球，專注生活穿搭實用性，為薄款連帽上衣，可穿著運動、逛街等，外面可多層式搭配，不過於太悶熱！具備適度份量感
                
                胸前藍色閃電象徵「能量」
                傳遞累了，該好好休息
                為自己充電，再充滿電的下一次 
                重新帶著這份的能量 繼續發熱吧！
                
                【注意事項】確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨
                
                【商品數據】為人工測量，可能會有正負2cm以內誤差，請商品以實物為主
                
                【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主`,
                prodDescImgs: [
                    // '/image/product/2021-5/2021hoodie-BK-1--d--1--md.webp',
                    // '/image/product/2021-5/2021hoodie-BK-1--d--2--md.webp',
                ],
                descImgs: [
                    '/image/product/2023-01/desc/CARRYON 22FW 連帽上衣 淺灰/1.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 連帽上衣 淺灰/2.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 連帽上衣 淺灰/3.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 連帽上衣 淺灰/4.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 連帽上衣 淺灰/5.png',
                ],
            },
            {
                sku: '22FW corduroy pants-BG1',
                caption: 'CARRYON 22FW Logo Pant 刺繡 燈芯絨褲 卡其',
                spec: '28, 30, 32, 34',
                specImg:
                    '/image/product/2023-01/size/CARRYON 22FW Logo Pant 刺繡 燈芯絨褲.webp',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 1880,
                specialOffer: 1500,
                inStock: true,
                image: '/image/product/2023-01/pant_1',
                fittingDesc: [
                    ['Female', 'Height：159cm', 'Weight：44kg', 'Size：30', '寬鬆'],
                    ['Female', 'Height：161cm', 'Weight：44kg', 'Size：28', '適中'],
                    ['Male', 'Height：165cm', 'Weight：55kg', 'Size：30', '適中'],
                    ['Male', 'Height：174cm', 'Weight：63kg', 'Size：30', '適中'],
                    ['Male', 'Height：183cm', 'Weight：68kg', 'Size：34', '適中'],
                ],
                desc: `CARRYON 22FW Logo Pant 刺繡 燈芯絨褲 卡其
        
                秋冬穿搭必備單品-燈心絨褲，為寒冷的冬天注入暖感，研發出專屬的寬鬆直筒版型，穿搭起來超有型！共有兩種口袋的設定，日常攜帶的手機錢包都能輕易放入
                
                版型寬鬆舒適，一穿比例馬上拉高，細節處於膝蓋上置入刺繡 Logo，背後則有 CARRYON 標籤，前後呼應！
                
                【注意事項】確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨
                
                【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主
                
                【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主`,
                prodDescImgs: [
                    '/image/product/2023-01/pant_4.webp',
                    // '/image/product/2021-5/2021hoodie-BK-1--d--2--md.webp',
                ],
                descImgs: [
                    '/image/product/2023-01/desc/CARRYON 22FW 燈心絨褲 卡其/1.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 燈心絨褲 卡其/2.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 燈心絨褲 卡其/3.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 燈心絨褲 卡其/4.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 燈心絨褲 卡其/5.png',
                ],
            },
            {
                sku: '22FW corduroy pants-KH1',
                caption: 'CARRYON 22FW Logo Pant 刺繡 燈芯絨褲 灰綠',
                spec: '28, 30, 32, 34',
                specImg:
                    '/image/product/2023-01/size/CARRYON 22FW Logo Pant 刺繡 燈芯絨褲.webp',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 1880,
                specialOffer: 1500,
                inStock: false,
                image: '/image/product/2023-01/pant_3',
                fittingDesc: [
                    ['Female', 'Height：159cm', 'Weight：44kg', 'Size：30', '寬鬆'],
                    ['Female', 'Height：161cm', 'Weight：44kg', 'Size：28', '適中'],
                    ['Male', 'Height：165cm', 'Weight：55kg', 'Size：30', '適中'],
                    ['Male', 'Height：174cm', 'Weight：63kg', 'Size：30', '適中'],
                    ['Male', 'Height：183cm', 'Weight：68kg', 'Size：34', '適中'],
                ],
                desc: `CARRYON 22FW Logo Pant 刺繡 燈芯絨褲 灰綠
        
                秋冬穿搭必備單品-燈心絨褲，為寒冷的冬天注入暖感，研發出專屬的寬鬆直筒版型，穿搭起來超有型！共有兩種口袋的設定，日常攜帶的手機錢包都能輕易放入
                
                版型寬鬆舒適，一穿比例馬上拉高，細節處於膝蓋上置入刺繡 Logo，背後則有 CARRYON 標籤，前後呼應！
                
                【 注意事項】確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨
                
                【 商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主
                
                【 色差 】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主`,
                prodDescImgs: [
                    '/image/product/2023-01/pant_5.webp',
                    // '/image/product/2021-5/2021hoodie-BK-1--d--2--md.webp',
                ],
                descImgs: [
                    '/image/product/2023-01/desc/CARRYON 22FW 燈心絨褲 軍綠/1.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 燈心絨褲 軍綠/2.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 燈心絨褲 軍綠/3.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 燈心絨褲 軍綠/4.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 燈心絨褲 軍綠/5.png',
                ],
            },
            // {
            //     sku: '22FW beanie-BK1',
            //     caption: 'CARRYON 22FW Logo Beanie 小標毛帽 黑',
            //     spec: 'One Size',
            //     specImg:
            //         '/image/product/2023-01/size/CARRYON 22FW Logo Beanie 小標毛帽.webp',
            //     buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
            //     price: 880,
            //     specialOffer: 700,
            //     inStock: true,
            //     image: '/image/product/2023-01/beanie-BK1',
            //     fittingDesc: [['N/A']],
            //     desc: `CARRYON 22FW Logo Beanie 小標毛帽 黑
        
            //     採用針織組織製作，配戴時整體包覆性更佳，不易變形，能在良好的帽型下穿搭，享受舒適保暖！能看見右下細節處注入全新織標，正面為 logo，反面則是標語「YOU TO CARRYON FOU YOU」，呼籲品牌理念
                
            //     推出基礎配色黑色 / 軍綠 
                
            //     尺寸：高 16cm/寬 18cm，有彈性(56-60cm)
                 
            //     【注意事項】確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨
                
            //     【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主
                
            //     【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主`,
            //     prodDescImgs: [
            //         // '/image/product/2023-01/beanie_1.webp',
            //         // '/image/product/2023-01/beanie_2.webp',
            //     ],
            //     descImgs: [
            //         '/image/product/2023-01/desc/CARRYON 22FW 毛帽 黑/1.png',
            //         '/image/product/2023-01/desc/CARRYON 22FW 毛帽 黑/2.png',
            //         '/image/product/2023-01/desc/CARRYON 22FW 毛帽 黑/3.png',
            //         '/image/product/2023-01/desc/CARRYON 22FW 毛帽 黑/4.png',
            //     ],
            // },
            {
                sku: '22FW beanie-KH1',
                caption: 'CARRYON 22FW Logo Beanie 小標毛帽 軍綠',
                spec: 'One Size',
                specImg:
                    '/image/product/2023-01/size/CARRYON 22FW Logo Beanie 小標毛帽.webp',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 880,
                specialOffer: 700,
                inStock: false,
                image: '/image/product/2023-01/beanie-KH1',
                fittingDesc: [['N/A']],
                desc: `CARRYON 22FW Logo Beanie 小標毛帽 軍綠
        
                採用針織組織製作，配戴時整體包覆性更佳，不易變形，能在良好的帽型下穿搭，享受舒適保暖！能看見右下細節處注入全新織標，正面為 logo，反面則是標語「YOU TO CARRYON FOU YOU」，呼籲品牌理念
                
                推出基礎配色 黑色 / 軍綠 
                
                尺寸：高 16cm/寬 18cm，有彈性(56-60cm)
                 
                【注意事項】確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨
                
                【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主
                
                【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主`,
                prodDescImgs: [
                    // '/image/product/2023-01/beanie_1.webp',
                    // '/image/product/2023-01/beanie_2.webp',
                ],
                descImgs: [
                    '/image/product/2023-01/desc/CARRYON 22FW 毛帽 軍綠/1.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 毛帽 軍綠/2.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 毛帽 軍綠/3.png',
                ],
            },
            {
                sku: '22FW logo fuction outdoor bag-BK1',
                caption: 'CARRYON 22FW CORDURA® Pocket Pouch 機能外出收納包（大）',
                spec: 'One Size',
                specImg:
                    '/image/product/2023-01/size/CARRYON 22FW CORDURA® Pocket Pouch 機能外出收納包.webp',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 880,
                specialOffer: 700,
                inStock: false,
                image:
                    '/image/product/2023-01/CARRYON 22FW Pocket Pouch 機能外出收納包（大）',
                fittingDesc: [['N/A']],
                desc: `CARRYON 22FW Pocket Pouch 機能外出收納包（大）
        
                經典 Logo 燙印，兩分層收納，將短途外出必需攜帶的物品一併納入收納需求，布料採用 CORDURA® 防潑水尼龍面料，內附抽繩背帶、掛腕戴，包體結構設計可自行更換喜歡的吊繩，正面拉鍊夾層最大可容納 6.5 寸手機，背面插袋夾層可收納零錢、卡片等小物
                
                尺寸：長度 16cm / 寬度 11cm                                                                                                             
                
                【 注意事項】確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨
                
                【 商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主
                
                【 色差 】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主`,
                prodDescImgs: [
                    // '/image/product/2021-5/2021hoodie-BK-1--d--1--md.webp',
                    // '/image/product/2021-5/2021hoodie-BK-1--d--2--md.webp',
                ],
                descImgs: [],
            },
            {
                sku: '22FW logo fuction outdoor bag-S1',
                caption: 'CARRYON 22FW CORDURA® Pocket Pouch 機能外出收納包（小）',
                spec: 'One Size',
                specImg:
                    '/image/product/2023-01/size/CARRYON 22FW CORDURA® Pocket Pouch 機能外出收納包.webp',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 780,
                specialOffer: 620,
                inStock: true,
                image:
                    '/image/product/2023-01/CARRYON 22FW Pocket Pouch 機能外出收納包（小）',
                fittingDesc: [['N/A']],
                desc: `"CARRYON 22FW CORDURA® Pocket Pouch 機能外出收納包（小）
        
                採用 CORDURA® 防潑水尼龍面料，內附抽繩背帶，包體結構設計可自行更換喜歡的吊繩，背面插袋夾層可收納零錢、卡片等小物，經典 Logo 燙印，兩分層收納，將短途外出必需攜帶的物品一併納入收納需求
                
                共推出黑色/卡其/軍綠
                
                尺寸：長度 13cm / 寬度 9cm 
                
                【 注意事項】確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨
                
                【 商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主
                
                【 色差 】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主`,
                prodDescImgs: [
                    // '/image/product/2021-5/2021hoodie-BK-1--d--1--md.webp',
                    // '/image/product/2021-5/2021hoodie-BK-1--d--2--md.webp',
                ],
                descImgs: [
                    '/image/product/2023-01/desc/CARRYON 22FW 機能外出收納包（小） /1.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 機能外出收納包（小） /2.png',
                ],
            },
            // {
            //     sku: '22SSAugustSocks-BK1',
            //     caption: 'CARRYON 22S/S August Logo 長襪  黑',
            //     spec: 'One Size',
            //     specImg: '/image/product/2022-8/decs/襪子尺寸表.webp',
            //     buyLink:
            //         'https://forms.gle/htNB6sDWh8ZB8oaD9',
            //     price: 380,
            //     specialOffer: 300,
            //     inStock: false,
            //     image: '/image/product/2022-8/CARRYON 22S_S August Logo 長襪 黑色',
            //     fittingDesc: [['N/A']],
            //     desc: `CARRY ON 22SS August 夏季別注系列 Logo 長襪 黑
        
            //     夏季別注系列以基本Logo作為設計，
            //     將生活中所追求的突破與創新，與品牌理念融合，
            //     首次推出運動毛巾底 Logo 長襪，                                    
            //     腳趾、腳底、腳踝三段式加厚保護，既舒適又排汗，CP值滿分！     
                                                                                                                 
            //     - 產品成分：棉 (精梳) 62%、彈性纖維16% 、膠絲 (莱卡) 14%、尼龍 8%                                                                                                  
              
            //     - 本產品使用運動毛巾底，舒適排汗，材質富有彈性，耐磨耐穿，四季皆適。
                
            //     - 注意事項：確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨 。                                       "
            //     `,
            //     prodDescImgs: [
            //         // '/image/product/2021-5/2021hoodie-BK-1--d--1--md.webp',
            //         // '/image/product/2021-5/2021hoodie-BK-1--d--2--md.webp',
            //     ],
            //     descImgs: [
            //         '/image/product/2022-8/decs/22SSAugustSocks-BK1/1.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-BK1/2.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-BK1/3.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-BK1/4.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-BK1/5.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-BK1/6.webp',
            //     ],
            // },
            // {
            //     sku: '22SSAugustSocks-WH1',
            //     caption: 'CARRYON 22S/S August Logo 長襪  白',
            //     spec: 'One Size',
            //     specImg: '/image/product/2022-8/decs/襪子尺寸表.webp',
            //     buyLink:
            //         'https://forms.gle/htNB6sDWh8ZB8oaD9',
            //     price: 380,
            //     specialOffer: 300,
            //     inStock: false,
            //     image: '/image/product/2022-8/CARRYON 22S_S August Logo 長襪 白色',
            //     fittingDesc: [['N/A']],
            //     desc: `CARRY ON 22SS August 夏季別注系列 Logo 長襪 白
        
            //     夏季別注系列以基本Logo作為設計，
            //     將生活中所追求的突破與創新，與品牌理念融合，
            //     首次推出運動毛巾底 Logo 長襪，                                    
            //     腳趾、腳底、腳踝三段式加厚保護，既舒適又排汗，CP值滿分！     
                                                                                                                 
            //     - 產品成分：棉 (精梳) 62%、彈性纖維16% 、膠絲 (莱卡) 14%、尼龍 8%                                                                                                  
              
            //     - 本產品使用運動毛巾底，舒適排汗，材質富有彈性，耐磨耐穿，四季皆適。
                
            //     - 注意事項：確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨 。                                       "
            //     `,
            //     prodDescImgs: [
            //         // '/image/product/2021-5/2021hoodie-BK-1--d--1--md.webp',
            //         // '/image/product/2021-5/2021hoodie-BK-1--d--2--md.webp',
            //     ],
            //     descImgs: [
            //         '/image/product/2022-8/decs/22SSAugustSocks-WH1/1.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-WH1/2.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-WH1/3.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-WH1/4.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-WH1/5.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-WH1/6.webp',
            //     ],
            // },
            // {
            //     sku: '22SSAugustSocks-Double',
            //     caption: 'CARRYON 22S/S August Logo 長襪  一組',
            //     spec: 'One Size',
            //     specImg: '/image/product/2022-8/decs/襪子尺寸表.webp',
            //     buyLink:
            //         'https://forms.gle/htNB6sDWh8ZB8oaD9',
            //     price: 700,
            //     specialOffer: 630,
            //     inStock: false,
            //     image: '/image/product/2022-8/CARRYON 22S_S August Logo 長襪 一組',
            //     fittingDesc: [['N/A']],
            //     desc: `CARRY ON 22SS August 夏季別注系列 Logo 長襪 一組
        
            //     夏季別注系列以基本Logo作為設計，
            //     將生活中所追求的突破與創新，與品牌理念融合，
            //     首次推出運動毛巾底 Logo 長襪，                                    
            //     腳趾、腳底、腳踝三段式加厚保護，既舒適又排汗，CP值滿分！     
                                                                                                                 
            //     - 產品成分：棉 (精梳) 62%、彈性纖維16% 、膠絲 (莱卡) 14%、尼龍 8%                                                                                                  
              
            //     - 本產品使用運動毛巾底，舒適排汗，材質富有彈性，耐磨耐穿，四季皆適。
                
            //     - 注意事項：確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨 。                                       "
            //     `,
            //     prodDescImgs: [
            //         // '/image/product/2021-5/2021hoodie-BK-1--d--1--md.webp',
            //         // '/image/product/2021-5/2021hoodie-BK-1--d--2--md.webp',
            //     ],
            //     descImgs: [
            //         '/image/product/2022-8/decs/22SSAugustSocks-Double/1.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-Double/2.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-Double/3.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-Double/4.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-Double/5.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-Double/6.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-Double/7.webp',
            //         '/image/product/2022-8/decs/22SSAugustSocks-Double/8.webp',
            //     ],
            // },
            {
                sku: '22FW flight cap-BK1',
                caption: 'CARRYON 22FW Flight cap Logo 遮耳飛行帽 黑',
                spec: 'One Size',
                specImg: null,
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 1080,
                specialOffer: 860,
                inStock: false,
                image:
                    '/image/product/2023-01/CARRYON 22FW Flight cap Logo 遮耳飛行帽 黑',
                fittingDesc: [['N/A']],
                desc: `CARRYON 22FW Flight cap Logo 遮耳飛行帽 黑

                大家最愛的飛行帽來囉！簡約的 Logo 設計，因應季節採用內裡刷毛布料，能有效提升保溫效果，表面為防水面料，輕盈的設定不厚重，後方有伸縮拉繩可依照個人調整頭圍，則帽繩能固定好帽款，不輕易脫落，是出遊露營必備的輕量保暖單品，簡約的帽款蘊含著巧思，千萬別錯過！
                                                                                                                            
                【 注意事項】確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨
                
                【 商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主
                
                【 色差 】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主`,
                prodDescImgs: [
                    // '/image/product/2023-01/cap_3.webp',
                    // '/image/product/2023-01/cap_4.webp',
                    // '/image/product/2023-01/cap_5.webp',
                ],
                descImgs: [
                    '/image/product/2023-01/desc/CARRYON 22FW 飛行帽 黑/3.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 飛行帽 黑/4.png',
                    '/image/product/2023-01/desc/CARRYON 22FW 飛行帽 黑/5.png',
                ],
            },

            {
                sku: '22SSAugustCap-BK1',
                caption: 'CARRYON 22S/S  經典 Logo 電繡老帽',
                spec: 'One Size',
                specImg: '/image/product/2022-8/decs/22SSAugustCap-BK1/size.webp',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 980,
                specialOffer: 780,
                inStock: true,
                image: '/image/product/2022-8/CARRYON 22S_S  經典 Logo 刺繡老帽',
                fittingDesc: [['N/A']],
                desc: `CARRY ON 22SS August
                夏季別注系列 經典 Logo 電繡老帽
          
                夏季別注系列釋出多項新穎單品，迎接CARRYON 邁入的第三年，再次釋出經典不敗 Logo 電繡老帽， 感謝一路陪伴 CARRYON 的所有人             
                
                讓我們繼續堅持所有，成為自己想要的樣子，感謝過去堅持的自己，感謝身旁帶給你力量的那些愛人們。                                                                                                      
                                                                                                                      
                - 電繡 Logo                                                                                                            
               
                - 100%斜紋面料質感
                
                - 含調節式扣環，可調整大小
               
                - 帽圍：55-60 cm
               
                - 注意事項：確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨。
                `,
                prodDescImgs: [
                    // '/image/product/2021-5/2021hoodie-BK-1--d--1--md.webp',
                    // '/image/product/2021-5/2021hoodie-BK-1--d--2--md.webp',
                ],
                descImgs: [
                    '/image/product/2022-8/decs/22SSAugustCap-BK1/1.webp',
                    '/image/product/2022-8/decs/22SSAugustCap-BK1/2.webp',
                    '/image/product/2022-8/decs/22SSAugustCap-BK1/3.webp',
                    '/image/product/2022-8/decs/22SSAugustCap-BK1/4.webp',
                    '/image/product/2022-8/decs/22SSAugustCap-BK1/5.webp',
                ],
            },
            {
                isNew: true,
                type: 2,
                sku: '23SS sticker',
                caption: 'CARRYON 23SS Logo 防水霧面 貼紙組合包 五入',
                spec: 'O/S',
                specImg: '',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 350,
                specialOffer: 250,
                inStock: false,
                image: `/image/product/CARRYON 23SS/main/23SS sticker/1.png`,
                series: '23SS',
                fittingDesc: [],
                desc: 'CARRYON 23SS Logo 防水霧面 貼紙組合包                   \n\n▲ 尺寸: 5*5 cm \n\n▲ 此為防水材質，黏貼撕下不留痕跡\n\nMade In Taiwan\n\n【注意事項】欲購買請填寫表單，後續將有客服與您聯絡，確認已匯款金額\n\n【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主\n\n【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主',
                prodImgLength: 1,
                descImgs: [],
                descImgLength: 3
            },
            {
                isNew: true,
                type: 2,
                sku: '23SS Reusable food ware',
                caption: 'CARRYON 23SS Reusable food ware 小麥環保 四件餐具組',
                spec: 'O/S',
                specImg: '',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 280,
                specialOffer: 199,
                inStock: true,
                image: '',
                series: '23SS',
                fittingDesc: [],
                desc: 'CARRYON 23SS Reusable food ware 小麥環保 四件餐具組 \t\t\t\n\n配色：深藍色、小麥色、湖水綠、哈密瓜綠、天空藍\n\n尺寸: 5 * 10.5 * 2.3 cm\n\n材料：小麥秸稈\n\n不論是出門在外、辦公外食\n都需要一組環保餐具\n\n簡單好攜帶\n四件組一次滿足需求\n湯匙 叉子 筷子 刀子\n\n可拆卸的設計\n小小的一盒放在包包不佔空間\n餐具貼心的圓滑設計\n讓我們使用上更佳安全\n\nMade In Taiwan\n\n【注意事項】欲購買請填寫表單，後續將有客服與您聯絡，確認已匯款金額\n\n【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主\n\n【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主',
                prodImgLength: 2,
                descImgs: [],
                descImgLength: 0
            },
            {
                isNew: true,
                type: 2,
                sku: '23SS Reusable bag',
                caption: 'CARRYON 23SS Reusable bag 可折疊 抗撕裂 收納環保袋',
                spec: 'O/S',
                specImg: '',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 580,
                specialOffer: 450,
                inStock: true,
                image: '',
                series: '23SS',
                fittingDesc: [],
                desc: 'CARRYON 23SS Reusable bag 可折疊 抗撕裂 收納環保袋\n\n▲ 配色：黑色、軍綠\n\n▲ 尺寸: 65（含背帶）x36x26cm\n▲ 收納後尺寸：15x16cm\n▲ 容量:27L\n\n使用再生纖維100%面料製成\n防水抗撕裂材質 \n內層有一個小內袋可裝置小物\n\n網眼袋部分有鈕扣\n能放置許多物品，放入筆電也沒問題\n\n耐用又攜帶方便\n出門在外不用再花錢買袋子\n\nMade In Japan\n\n【注意事項】欲購買請填寫表單，後續將有客服與您聯絡，確認已匯款金額\n\n【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主\n\n【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主',
                prodImgLength: 2,
                descImgs: [],
                descImgLength: 4
            },
            {
                isNew: true,
                type: 2,
                sku: '23SS Ripstop bag',
                caption: 'CARRYON 23SS Ripstop bag 束口收納小包',
                spec: 'O/S',
                specImg: '',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 580,
                specialOffer: 450,
                inStock: true,
                image: '',
                series: '23SS',
                fittingDesc: [],
                desc: 'CARRYON 23SS Ripstop bag 束口收納小包\n\n\n▲ 配色：黑色、軍綠\n\n▲ 尺寸: 26x38cm\n▲ 束小包後尺寸：25cm\n▲ 容量: 6L\n\n使用再生纖維100%面料製成\n防水抗撕裂材質 \n內層有一個小內袋可裝置小物\n\n2WAY束帶式設計\n一拉馬上變成圓形小包\n出門攜帶方便\n\nMade In Japan\n\n【注意事項】欲購買請填寫表單，後續將有客服與您聯絡，確認已匯款金額\n\n【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主\n\n【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主',
                prodImgLength: 2,
                descImgs: [],
                descImgLength: 4
            },
            {
                isNew: true,
                type: 2,
                sku: '23SS logoLS-WH',
                caption: 'CARRYON 23SS Logo L/S Tee 電繡口袋厚磅薄長袖 白色',
                spec: 'XL-2XL',
                specImg: '/image/product/CARRYON 23SS/size/23SS logoLS-WH.jpg',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 1280,
                specialOffer: 1150,
                inStock: false,
                image: '',
                series: '23SS',
                fittingDesc: [
                    ['Female', 'Height：159cm', 'Weight：44kg', 'Size：XL', '寬鬆'],
                    ['Female', 'Height：161cm', 'Weight：41kg', 'Size：XL', '寬鬆'],
                    ['Male', 'Height：174cm', 'Weight：63kg', 'Size：XL', '合適'],
                    ['Male', 'Height：180cm', 'Weight：61kg', 'Size：2XL', '寬鬆'],
                    ['Male', 'Height：180cm', 'Weight：64kg', 'Size：2XL', '寬鬆'],
                ],
                desc: 'CARRYON 23SS Logo L/S Tee 電繡口袋厚磅薄長袖\n\n▲ 配色：黑色、白色、海軍藍\n▲ 尺寸：XL、2XL\n\n春秋冬必備單品-薄長袖\n\n23SS 特別增添口袋配置\n可以放置手機、零錢小物\n\n採用電繡Logo工法\n低調簡單設計穿搭沒煩惱\n\n選用10支棉構成\n並以100％全精梳棉製作\n親膚感十足，穿起來舒適\n\nMade In Taiwan\n\n【注意事項】欲購買請填寫表單，後續將有客服與您聯絡，確認已匯款金額\n\n【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主\n\n【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主',
                prodImgLength: 3,
                descImgs: [],
                descImgLength: 4
            },
            {
                isNew: true,
                type: 2,
                sku: '23SS logoLS-BK',
                caption: 'CARRYON 23SS Logo L/S Tee 電繡口袋厚磅薄長袖 黑色',
                spec: 'XL-2XL',
                specImg: '/image/product/CARRYON 23SS/size/23SS logoLS-BK.jpg',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 1280,
                specialOffer: 1150,
                inStock: false,
                image: '',
                series: '23SS',
                fittingDesc: [
                    ['Female', 'Height：159cm', 'Weight：44kg', 'Size：XL', '寬鬆'],
                    ['Female', 'Height：161cm', 'Weight：41kg', 'Size：XL', '寬鬆'],
                    ['Male', 'Height：174cm', 'Weight：63kg', 'Size：XL', '合適'],
                    ['Male', 'Height：180cm', 'Weight：61kg', 'Size：2XL', '寬鬆'],
                    ['Male', 'Height：180cm', 'Weight：64kg', 'Size：2XL', '寬鬆'],
                ],
                desc: 'CARRYON 23SS Logo L/S Tee 電繡口袋厚磅薄長袖\n\n▲ 配色：黑色、白色、海軍藍\n▲ 尺寸：XL、2XL\n\n春秋冬必備單品-薄長袖\n\n23SS 特別增添口袋配置\n可以放置手機、零錢小物\n\n採用電繡Logo工法\n低調簡單設計穿搭沒煩惱\n\n選用10支棉構成\n並以100％全精梳棉製作\n親膚感十足，穿起來舒適\n\nMade In Taiwan\n\n【注意事項】欲購買請填寫表單，後續將有客服與您聯絡，確認已匯款金額\n\n【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主\n\n【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主',
                prodImgLength: 3,
                descImgs: [],
                descImgLength: 4
            },
            {
                isNew: true,
                type: 2,
                sku: '23SS logoLS-NY',
                caption: 'CARRYON 23SS Logo L/S Tee 電繡口袋厚磅薄長袖 海軍藍',
                spec: 'XL-2XL',
                specImg: '/image/product/CARRYON 23SS/size/23SS logoLS-NY.jpg',
                buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
                price: 1280,
                specialOffer: 1150,
                inStock: false,
                image: '',
                series: '23SS',
                fittingDesc: [
                    ['Female', 'Height：159cm', 'Weight：44kg', 'Size：XL', '寬鬆'],
                    ['Female', 'Height：161cm', 'Weight：41kg', 'Size：XL', '寬鬆'],
                    ['Male', 'Height：174cm', 'Weight：63kg', 'Size：XL', '合適'],
                    ['Male', 'Height：180cm', 'Weight：61kg', 'Size：2XL', '寬鬆'],
                    ['Male', 'Height：180cm', 'Weight：64kg', 'Size：2XL', '寬鬆'],
                ],
                desc: 'CARRYON 23SS Logo L/S Tee 電繡口袋厚磅薄長袖\n\n▲ 配色：黑色、白色、海軍藍\n▲ 尺寸：XL、2XL\n\n春秋冬必備單品-薄長袖\n\n23SS 特別增添口袋配置\n可以放置手機、零錢小物\n\n採用電繡Logo工法\n低調簡單設計穿搭沒煩惱\n\n選用10支棉構成\n並以100％全精梳棉製作\n親膚感十足，穿起來舒適\n\nMade In Taiwan\n\n【注意事項】欲購買請填寫表單，後續將有客服與您聯絡，確認已匯款金額\n\n【商品數據】為人工測量，可能會有正負 2cm 以內誤差，請商品以實物為主\n\n【色差】所有商品圖片皆為實物拍攝，可能由於拍攝光線、電腦顯示器色差等因素，照片與實物會略有差別，請商品以實物為主',
                prodImgLength: 3,
                descImgs: [],
                descImgLength: 3
            },
        //     {
        //         sku: '22ssAugustTee-WH1',
        //         caption: 'CARRYON 22S/S 經典 Logo 短T 白',
        //         spec: 'XL-3XL',
        //         specImg: '/image/product/2022-8/decs/22ssAugustTee-WH1/size.webp',
        //         buyLink: 'https://forms.gle/htNB6sDWh8ZB8oaD9',
        //         price: 980,
        //         specialOffer: 780,
        //         inStock: false,
        //         image: '/image/product/2022-8/CARRYON 22S_S 經典 Logo 短T 白',
        //         fittingDesc: [
        //             ['Female', 'Height：160cm', 'Weight：44kg', 'Size：2XL', '寬鬆'],
        //             ['Male', 'Height：180cm', 'Weight：61kg', 'Size：3XL', '寬鬆'],
        //             ['Male', 'Height：174cm', 'Weight：63kg', 'Size：2XL', '寬鬆'],
        //         ],
        //         desc: `CARRY ON 22SS August
        //         夏季別注系列  經典 Logo 短T 白
        
        //         堅持，可以是心酸，可以是甜膩，而在歷經了不同階段的挑戰後，是時候停下腳步，看看自己現在的樣子，並給予自己一份肯定。
              
        //         夏季別注系列以基本Logo作為設計，將那些生活中所追求的突破與創新，與品牌理念融合，跟著我們回顧經典，擁抱創新，向過去的自己致敬，並與未來的自己說聲繼續加油！
                                               
                                                                                                                       
        //  - 材質採用台灣棉質製成，白色款式特別使用不透膚材質，穿著上如果流汗後不再怕太透。
        
        //  - 注意事項：確認訂購請填寫表單，後續將有客服與您聯絡，確認已匯款金額，少量先製現貨，首購搶先出貨。
        //         `,
        //         prodDescImgs: [
        //             // '/image/product/2021-5/2021hoodie-BK-1--d--1--md.webp',
        //             // '/image/product/2021-5/2021hoodie-BK-1--d--2--md.webp',
        //         ],
        //         descImgs: [
        //             '/image/product/2022-8/decs/22ssAugustTee-WH1/1.webp',
        //             '/image/product/2022-8/decs/22ssAugustTee-WH1/2.webp',
        //             '/image/product/2022-8/decs/22ssAugustTee-WH1/3.webp',
        //             '/image/product/2022-8/decs/22ssAugustTee-WH1/4.webp',
        //             '/image/product/2022-8/decs/22ssAugustTee-WH1/5.webp',
        //             '/image/product/2022-8/decs/22ssAugustTee-WH1/6.webp',
        //         ],
        //     },
        ]
    }
]




export default onSale;