import React from 'react';

import { Link } from 'react-router-dom';

// import { useMediaQuery } from 'react-responsive';


import utility from '../../utility';


import styles from './Products.module.scss';

import prods from '../../productList';



const Products = () => {

    const indexList = [1, 2];

    const seriesList = indexList.map((index) => {

        const { source, list } = prods[index];

        // return {
        //     source,
        //     list: [].concat(list.filter((data) => data.inStock)).concat(list.filter((data) => !data.inStock))
        // }

        return [].concat(list)

    });
   
    const flatSeriesList = seriesList.flatMap(subArray => subArray);

    // 把售完商品丟到最後
    const listData = flatSeriesList.filter((data) => data.inStock).concat(flatSeriesList.filter((data) => !data.inStock))

    return (
        <div className={styles.wrap}>
            <h2 style={{ textTransform: 'uppercase' }}>
                flying off the shelves
            </h2>
            <div className={utility.mixClasses(styles.row, styles.prodWrap)} >
                {
                    listData
                        .map(({ sku, caption, image, series, spec, price, inStock, specialOffer }) => (
                            <div key={sku} className={styles.prod}>
                                <Link to={`/product/${sku}${utility.const.product.pathOperator}${caption.replace(utility.const.product.pathRegExp, utility.const.product.pathReplaceChar)}`} className={utility.mixClasses('', '')}>
                                    <div className={styles.imgWrap} >
                                        <img src={encodeURI(`https://assets.carryon-clothing.com/${series}/product/${sku}/1.png`)} alt={caption} width="200" height="200" loading="lazy" />
                                        {
                                            !inStock
                                                ? <div className={styles.stock}>售完</div>
                                                : null
                                        }
                                    </div>
                                    <div className={styles.caption}>{caption}</div>
                                    <div className={styles.sub}>
                                        {/* <div className={styles.spec}>{spec}</div> */}
                                        <div className={utility.mixClasses('jso-price', styles.price)}>
                                            <span className={specialOffer && styles.specialOfferLineThrough}>
                                                {utility.priceWithCommas(price)}
                                            </span>
                                            {
                                                specialOffer && (
                                                    <span className={styles.specialOffer}>
                                                        {utility.priceWithCommas(specialOffer)}
                                                    </span>
                                                )
                                            }
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    // seriesList.map(({ source, list }) => (
                    //     source === 'aws'
                    //     ? (
                    //         list
                    //             .map(({ sku, caption, image, series, spec, price, inStock, specialOffer }) => (
                    //                 <div key={sku} className={styles.prod}>
                    //                     <Link to={`/product/${sku}${utility.const.product.pathOperator}${caption.replace(utility.const.product.pathRegExp, utility.const.product.pathReplaceChar)}`} className={utility.mixClasses('', '')}>
                    //                         <div className={styles.imgWrap} >
                    //                             <img src={encodeURI(`https://assets.carryon-clothing.com/${series}/product/${sku}/1.png`)} alt={caption} width="200" height="200" loading="lazy" />
                    //                             {
                    //                                 !inStock
                    //                                     ? <div className={styles.stock}>售完</div>
                    //                                     : null
                    //                             }
                    //                         </div>
                    //                         <div className={styles.caption}>{caption}</div>
                    //                         <div className={styles.sub}>
                    //                             {/* <div className={styles.spec}>{spec}</div> */}
                    //                             <div className={utility.mixClasses('jso-price', styles.price)}>
                    //                                 <span className={specialOffer && styles.specialOfferLineThrough}>
                    //                                     {utility.priceWithCommas(price)}
                    //                                 </span>
                    //                                 {
                    //                                     specialOffer && (
                    //                                         <span className={styles.specialOffer}>
                    //                                             {utility.priceWithCommas(specialOffer)}
                    //                                         </span>
                    //                                     )
                    //                                 }
                    //                             </div>
                    //                         </div>
                    //                     </Link>
                    //                 </div>
                    //             ))
                    //     )
                    //     : (
                    //         list
                    //             .map(({ sku, caption, image, series, spec, price, inStock, specialOffer }) => (
                    //                 <div key={sku} className={styles.prod}>
                    //                     <Link to={`/product/${sku}${utility.const.product.pathOperator}${caption.replace(utility.const.product.pathRegExp, utility.const.product.pathReplaceChar)}`} className={utility.mixClasses('', '')}>
                    //                         <div className={styles.imgWrap} >
                    //                             <img src={`/image/product/${series}/main/${sku}/1.png`} alt={caption} width="200" height="200" loading="lazy" />
                    //                             {
                    //                                 !inStock
                    //                                     ? <div className={styles.stock}>售完</div>
                    //                                     : null
                    //                             }
                    //                         </div>
                    //                         <div className={styles.caption}>{caption}</div>
                    //                         <div className={styles.sub}>
                    //                             {/* <div className={styles.spec}>{spec}</div> */}
                    //                             <div className={utility.mixClasses('jso-price', styles.price)}>
                    //                                 <span className={specialOffer && styles.specialOfferLineThrough}>
                    //                                     {utility.priceWithCommas(price)}
                    //                                 </span>
                    //                                 {
                    //                                     specialOffer && (
                    //                                         <span className={styles.specialOffer}>
                    //                                             {utility.priceWithCommas(specialOffer)}
                    //                                         </span>
                    //                                     )
                    //                                 }
                    //                             </div>
                    //                         </div>
                    //                     </Link>
                    //                 </div>
                    //             ))
                    //     )
                    // ))
                }
            </div>
        </div>
    )

}

export default React.memo(Products);
