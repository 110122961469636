import React from 'react';

import MetaTag from '../components/MetaTag';

import Layout from '../components/Layout';
// import Animate from '../components/common/Animate';

import styles from './ShippingAndReturns.module.scss';

const ShippingAndReturns = () => (
    <Layout>
        <MetaTag
            title="運送及退款"
            url="https://www.carryon-clothing.com/shipping-and-returns"
        />
        <div className={styles.wrap}>
            <div className={styles.row}>
                <h1>運送及退款</h1>
                <h2>配送方式 </h2>
                <div>
                    <ol>
                        <li>
                            超商店到店取貨：主要寄貨超商為 7-11 或 全家，務必至 Google 表單填寫指定配送超商資訊。
                        </li>
                        <li>
                            面交：統一面交地點，地址：Evopace 新北市土城區裕民路83-1號B1。
                        </li>
                    </ol>
                </div>
                <h2>運費計算</h2>
                <div>
                    <ol>
                        <li>
                            超商店到店取貨：單筆訂單運費為 $60 元。
                        </li>
                    </ol>
                </div>
                <h2>商品運送</h2>
                <div>
                    <ol>
                        <li>
                            超商店到店取貨：寄送範圍限於台灣本島，有少許部分門市無提供貨運服務，務必注意取件門市，商品將於1至3個工作天送達，請留意手機簡訊並於七天內取貨。
                        </li>
                        <li>
                            海外配送：將使用順豐速運寄出，運費為貨到付款，部分商品可能會有無法配送或清關等問題，建議您先確認商品是否可送達指定國家後再購買。
                        </li>
                    </ol>
                </div>
                <h2>商品退換</h2>
                <div>
                    <ol>
                        <li>
                            商品除非有做工瑕疵問題，皆為限量預購，將不提供退貨的服務，若遇到瑕疵退貨須於七日內與我們官方聯絡，若超過不予受理。
                            <br />
                            ◎商品若有以下情況，恕無法受理退貨申請：
                            <ul>
                                <li>超過七天鑑賞期限</li>
                                <li>商品包裝不完整</li>
                                <li>商品沾染彩妝及污漬</li>
                                <li>商品下水清洗</li>
                            </ul>
                        </li>
                        <li>
                            退 / 換貨商品寄回資訊：與客服專員聯絡，即可告知退貨地址。
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </Layout>
);

export default ShippingAndReturns;
