import React from 'react';

// import { Link } from 'react-router-dom';

// import { useMediaQuery } from 'react-responsive';

import SlideUp from '../SlideUp';

import utility from '../../utility';


import styles from './Posts.module.scss';

import list from '../../postList'


const Posts = () => {

    return (
        <div className={styles.wrap}>
            <h2>OOTD</h2>
            <div className={utility.mixClasses(styles.row, styles.postWrap)} >
                {
                    list.map(({ url, desc, image, source }, idx) => (
                        <div key={url} className={styles.post}>
                            <SlideUp delayOffset={idx}>
                                <a href={url} className={utility.mixClasses('', '')} target="_blank" rel="noreferrer noopener">
                                    <div className={styles.imageWrap}>
                                        <img src={image} alt={desc} width="200" height="200" loading="lazy" />
                                    </div>
                                    <div className={styles.desc}>{desc}</div>
                                    <div className={styles.sub}>
                                        {/* <div className={styles.spec}>{spec}</div> */}
                                        <div className={styles.source}>{source}</div>
                                    </div>
                                </a>
                            </SlideUp>
                        </div>
                    ))
                }
            </div>
        </div>
    )

}

export default React.memo(Posts);
