import React from 'react';

import MetaTag from '../components/MetaTag';


import Layout from '../components/Layout';
// import Animate from '../components/common/Animate';

import styles from './BuyingGuide.module.scss';

const BuyingGuide = () => (
    <Layout>
        <MetaTag
            title="購物指南"
            url="https://www.carryon-clothing.com/buying-guide"
        />
        <div className={styles.wrap}>
            <div className={styles.row}>
                <h1>購物指南</h1>
                <p>填寫表單 {'>'} 私訊官方 instagram {'>'} 確認匯款金額 {'>'} 付款 {'>'} 預購等待 {'>'} 出貨收取包裹</p>
                <h2>購買方式</h2>
                <div>
                    <ol>
                        <li>
                            點選商品購買連結，至 Google 表單填寫購買資訊。
                        </li>
                        <li>
                            填寫完表單後，請私訊官方 instagram：<a href="https://www.instagram.com/carryon_clothing_/?utm_source=carryon&utm_medium=buying_guide" target="_blank" rel="noreferrer noopener">carryon_clothing_</a>，將有客服專員與您聯絡，確認匯款金額及購買款式的顏色/尺寸/數量。
                        </li>
                    </ol>
                </div>
                <h2>付款方式</h2>
                <div>
                    <ol>
                        <li>
                            銀行轉帳 ATM：與客服專員確認金額後，於 48 小時內將購買金額匯款至指定戶頭，若時效超過不保留訂單。
                        </li>
                        <li>
                            面交：面交付款，地址：Evopace 新北市土城區裕民路83-1號B1。
                        </li>
                    </ol>
                </div>
                <h2>預購商品需要多久時間才能到貨？</h2>
                <p>單品若少量現貨售完，將會預購再製作，單品將於預購截止後於 7-14 天後到貨寄出。</p>
                <h2>商品售完會再有嗎？</h2>
                <p>每一檔單品將於售完不再販售，若有需求起訂量 20 件以上，可於客服人員聯絡。</p>
                <h2>尺寸問題</h2>
                <p>商品描述頁皆有放上尺寸表，請依照尺寸表所提供的資訊，去購買您合適的尺寸。</p>
            </div>
        </div>
    </Layout>
);

export default BuyingGuide;
