const list = [
    {
        source: 'aws',
        id: '24SS Chillax',
        title: '24SS Chillax 系列',
        series: '24SS Chillax',
        desc: `
24SS Chillax 系列

Just Chill, everything’s going to be OK

放輕鬆，一切都會更好

在繁忙的都市生活中，持續的努力讓我們感到疲憊。

追夢的過程中，別忘了放輕鬆

無論是參加派對、享受酒精、沉浸在玩樂，

還是擁抱大自然

在每一個瞬間找到屬於自己的平靜與快樂。
        

        `,
        cover: 'https://assets.carryon-clothing.com/24SS Chillax/story/cover.png',
        banner: 'https://assets.carryon-clothing.com/24SS Chillax/banner/1.mp4',
        bannerType: 'video',
        imageLength: 10
    },
    {
        source: 'aws',
        id: '24SSLAB 2nd Release',
        title: '24SSLAB 2nd Release 新品系列',
        series: '24SSLAB 2nd Release',
        desc: `
24SSLAB 2nd Release

專為女孩設計的支線
嚴選出不同的穿搭單品

穿搭又酷又跩
女孩帥起來特色十足
        `,
        cover: 'https://assets.carryon-clothing.com/24SSLAB 2nd Release/story/cover.png',
        banner: 'https://assets.carryon-clothing.com/24SSLAB 2nd Release/banner/1.png',
        bannerType: 'image',
        imageLength: 10
    },
    {
        source: 'aws',
        id: '23FW',
        title: 'Never Miss a Beat 秋冬系列',
        series: '23FW',
        desc: `
為了成為心中想要的樣子
不斷努力、朝著目標前進

設計理念「Never Miss a Beat」 
意味著不論是歌手、舞者、製作人等

聽到音樂、節奏都不會錯過任何一拍
與我們生活也都息息相關

永遠不錯過任何節奏>>>轉換成不放棄的態度
不錯過任何機會，堅定的向前
        `,
        cover: 'https://assets.carryon-clothing.com/23FW/story/cover.png',
        banner: 'https://assets.carryon-clothing.com/23FW/banner/23FW.mp4',
        bannerType: 'video',
        imageLength: 20
    },
    {
        source: 'aws',
        id: '23SS September',
        title: '23SS September Collection',
        series: '23SS September',
        desc: `
        夏季設計款出爐🔥

        在生活中 音樂、跳舞與我們息息相關 🎧 已經深深印烙在心中 成為缺一不可的夥伴
        
        CARRYON 全新吉祥物-跑跑人🚶 帶出品牌理念 YOU TO CARRYON FOR YOU 為了自己堅持，成為心中想要的樣子 象徵堅持向前 充滿毅力、活潑的形象
        `,
        cover: 'https://assets.carryon-clothing.com/23SS September/story/cover.png',
        banner: 'https://assets.carryon-clothing.com/23SS September/banner/1.mp4',
        bannerType: 'video',
        imageLength: 15
    },
    {
        source: 'aws',
        id: 'LAB 23SS',
        title: 'LAB 23SS 愛心人女孩系列',
        series: '23SS LAB',
        desc: `
        女生力量 、堅強、CARRYON 的少女心

        女孩隨著成長
        每一個階段 
        都有不同的關卡要突破
        但記得
        堅持自己的少女心
        保有少女心
        `,
        cover: 'https://assets.carryon-clothing.com/23SS LAB/story/cover.png',
        banner: 'https://assets.carryon-clothing.com/23SS LAB/banner/1.jpg',
        bannerType: 'image',
        imageLength: 15
    },
    {
        id: '23SS',
        title: '春夏 ON&OFF 新品系列',
        desc: `
        追求夢想的過程中，有時苦有時甜
        請跟隨自己的心，隨時切換模式，調整自己步調
        慢慢來，堅持久了就是你的
        
        Be fearless.
        無所畏懼往前的追逐吧
        `,
        cover: '/image/sp/23SS/cover.png',
        banner: '/video/CARRYON 23SS.m4v',
        images: [
            {
                src: '/image/sp/23SS/1.webp',
                thumbnail: '/image/sp/23SS/1.webp',
            },
            {
                src: '/image/sp/23SS/2.webp',
                thumbnail: '/image/sp/23SS/2.webp',
            },
            {
                src: '/image/sp/23SS/3.webp',
                thumbnail: '/image/sp/23SS/3.webp',
                
            },
            {
                src: '/image/sp/23SS/4.webp',
                thumbnail: '/image/sp/23SS/4.webp',                
            },
            {
                src: '/image/sp/23SS/5.webp',
                thumbnail: '/image/sp/23SS/5.webp',
            },
            {
                src: '/image/sp/23SS/6.webp',
                thumbnail: '/image/sp/23SS/6.webp',
            },
            {
                src: '/image/sp/23SS/7.webp',
                thumbnail: '/image/sp/23SS/7.webp',
            },
            {
                src: '/image/sp/23SS/8.webp',
                thumbnail: '/image/sp/23SS/8.webp',
            },
            {
                src: '/image/sp/23SS/9.webp',
                thumbnail: '/image/sp/23SS/9.webp',
            },
            {
                src: '/image/sp/23SS/10.webp',
                thumbnail: '/image/sp/23SS/10.webp',
            },
            {
                src: '/image/sp/23SS/11.webp',
                thumbnail: '/image/sp/23SS/11.webp',
            },
            {
                src: '/image/sp/23SS/12.webp',
                thumbnail: '/image/sp/23SS/12.webp',
            },
            {
                src: '/image/sp/23SS/13.webp',
                thumbnail: '/image/sp/23SS/13.webp',
            },
            
        ]
    },
    {
        id: '22FW',
        title: '冬季 Energy 新品系列',
        desc: `
        每個人皆有屬於自己吸取能量的方式
        有的走入自然 ; 有的沈溺於音符 ; 
        有的享受美食 ; 有的感受於旅遊...
        
        
        堅持的路上並不容易
        累的時候了 請停下來為自己充電
        在充滿電的下一次 
        重新帶著這份的能量 繼續發熱吧！
        
        `,
        cover: '/image/sp/22FW/cover.webp',
        banner: '/video/2022-12-31.mp4',
        images: [
            {
                src: '/image/sp/22FW/1.webp',
                thumbnail: '/image/sp/22FW/1.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/2.webp',
                thumbnail: '/image/sp/22FW/2.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/3.webp',
                thumbnail: '/image/sp/22FW/3.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/4.webp',
                thumbnail: '/image/sp/22FW/4.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/5.webp',
                thumbnail: '/image/sp/22FW/5.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/6.webp',
                thumbnail: '/image/sp/22FW/6.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/7.webp',
                thumbnail: '/image/sp/22FW/7.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/8.webp',
                thumbnail: '/image/sp/22FW/8.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/9.webp',
                thumbnail: '/image/sp/22FW/9.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/10.webp',
                thumbnail: '/image/sp/22FW/10.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/11.webp',
                thumbnail: '/image/sp/22FW/11.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/12.webp',
                thumbnail: '/image/sp/22FW/12.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/13.webp',
                thumbnail: '/image/sp/22FW/13.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/14.webp',
                thumbnail: '/image/sp/22FW/14.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/15.webp',
                thumbnail: '/image/sp/22FW/15.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW/16.webp',
                thumbnail: '/image/sp/22FW/16.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            
        ]
    },
    {
        id: 'CARRYON x FOREST 22FW',
        title: 'Stay High Stay Cool',
        desc: `
        在追逐夢想的過程中，難免有精疲力竭的時候，透過品嚐自己喜愛的事物，

        帶著這份動力，繼續前進；在生活中透過不斷的學習、攝取、消化，

        持續成長的同時請告訴自己：『保有能量，保持自己喜愛的樣貌！』
        
        `,
        cover: '/image/sp/22FW-FOREST/cover.webp',
        banner: '/image/banner/2022-10-3.webp',
        bannerType: 'image',
        images: [
            {
                src: '/image/sp/22FW-FOREST/1.webp',
                thumbnail: '/image/sp/22FW-FOREST/1.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 142,
                
            },
            {
                src: '/image/sp/22FW-FOREST/2.webp',
                thumbnail: '/image/sp/22FW-FOREST/2.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW-FOREST/3.webp',
                thumbnail: '/image/sp/22FW-FOREST/3.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW-FOREST/4.webp',
                thumbnail: '/image/sp/22FW-FOREST/4.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/22FW-FOREST/5.webp',
                thumbnail: '/image/sp/22FW-FOREST/5.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 142,
                
            },
            {
                src: '/image/sp/22FW-FOREST/6.webp',
                thumbnail: '/image/sp/22FW-FOREST/6.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 142,
                
            },
            {
                src: '/image/sp/22FW-FOREST/7.webp',
                thumbnail: '/image/sp/22FW-FOREST/7.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 142,
                
            },
            {
                src: '/image/sp/22FW-FOREST/8.webp',
                thumbnail: '/image/sp/22FW-FOREST/8.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 142,
                
            },
            {
                src: '/image/sp/22FW-FOREST/9.webp',
                thumbnail: '/image/sp/22FW-FOREST/9.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 142,
                
            },
            {
                src: '/image/sp/22FW-FOREST/10.webp',
                thumbnail: '/image/sp/22FW-FOREST/10.webp',
                thumbnailWidth: 80,
                thumbnailHeight: 142,
                
            },
            
        ]
    },
    {
        id: '22SS August',
        title: '夏季別注系列',
        desc: `
        堅持，可以是心酸，可以是甜膩，而在歷經了不同階段的挑戰後，是時候停下腳步，看看自己現在的樣子，並給予自己一份肯定。

        夏季別注系列以基本Logo作為設計，從未見過的單品、版型都在這一次完美呈現，將那些生活中所追求的突破與創新，與品牌理念融合，首次開箱寬鬆電繡牛仔褲、夏日辣妹短板T、多功能大側背包、大C長襪，並回顧從前的經典款黑色 老帽、基本款 Logo 短袖，向過去的自己致敬，並與未來的自己說聲繼續加油！

        感謝過去堅持的自己，感謝身旁帶給你力量的那些愛人們，讓我們帶著這份堅持，繼續展望未來！
        `,
        cover: '/image/sp/22SS August/cover.webp',
        banner: '/video/2022-8-6-20.mp4',
        images: [
            {
                src: '/image/sp/22SS August/1.webp',
                thumbnail: '/image/sp/22SS August/1.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 58,
                
            },
            {
                src: '/image/sp/22SS August/2.webp',
                thumbnail: '/image/sp/22SS August/2.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 58,
                
            },
            {
                src: '/image/sp/22SS August/3.webp',
                thumbnail: '/image/sp/22SS August/3.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
                
            },
            {
                src: '/image/sp/22SS August/4.webp',
                thumbnail: '/image/sp/22SS August/4.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
                
            },
            {
                src: '/image/sp/22SS August/5.webp',
                thumbnail: '/image/sp/22SS August/5.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
                
            },
            {
                src: '/image/sp/22SS August/6.webp',
                thumbnail: '/image/sp/22SS August/6.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
                
            },
            {
                src: '/image/sp/22SS August/7.webp',
                thumbnail: '/image/sp/22SS August/7.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,

            },
            {
                src: '/image/sp/22SS August/8.webp',
                thumbnail: '/image/sp/22SS August/8.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 58,                
            },
            {
                src: '/image/sp/22SS August/9.webp',
                thumbnail: '/image/sp/22SS August/9.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,

            },
            {
                src: '/image/sp/22SS August/10.webp',
                thumbnail: '/image/sp/22SS August/10.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,

            },
            {
                src: '/image/sp/22SS August/11.webp',
                thumbnail: '/image/sp/22SS August/11.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,

            },
            {
                src: '/image/sp/22SS August/12.webp',
                thumbnail: '/image/sp/22SS August/12.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 58,                
            },
            {
                src: '/image/sp/22SS August/13.webp',
                thumbnail: '/image/sp/22SS August/13.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,

            },
            {
                src: '/image/sp/22SS August/14.webp',
                thumbnail: '/image/sp/22SS August/14.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,

            },
            {
                src: '/image/sp/22SS August/15.webp',
                thumbnail: '/image/sp/22SS August/15.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,

            },
            {
                src: '/image/sp/22SS August/16.webp',
                thumbnail: '/image/sp/22SS August/16.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,

            },
            {
                src: '/image/sp/22SS August/17.webp',
                thumbnail: '/image/sp/22SS August/17.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,

            },
            {
                src: '/image/sp/22SS August/18.webp',
                thumbnail: '/image/sp/22SS August/18.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
            },
            {
                src: '/image/sp/22SS August/19.webp',
                thumbnail: '/image/sp/22SS August/19.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 58,
            },
            {
                src: '/image/sp/22SS August/20.webp',
                thumbnail: '/image/sp/22SS August/20.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
            },
            {
                src: '/image/sp/22SS August/21.webp',
                thumbnail: '/image/sp/22SS August/21.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
            },
            {
                src: '/image/sp/22SS August/22.webp',
                thumbnail: '/image/sp/22SS August/22.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
            },
            {
                src: '/image/sp/22SS August/23.webp',
                thumbnail: '/image/sp/22SS August/23.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
            },
            {
                src: '/image/sp/22SS August/24.webp',
                thumbnail: '/image/sp/22SS August/24.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
            },
            {
                src: '/image/sp/22SS August/25.webp',
                thumbnail: '/image/sp/22SS August/25.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
            },
            {
                src: '/image/sp/22SS August/26.webp',
                thumbnail: '/image/sp/22SS August/26.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
            },
            {
                src: '/image/sp/22SS August/27.webp',
                thumbnail: '/image/sp/22SS August/27.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
            },
            {
                src: '/image/sp/22SS August/28.webp',
                thumbnail: '/image/sp/22SS August/28.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 58,                
            },
            {
                src: '/image/sp/22SS August/29.webp',
                thumbnail: '/image/sp/22SS August/29.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
            },
            {
                src: '/image/sp/22SS August/30.webp',
                thumbnail: '/image/sp/22SS August/30.webp',
                thumbnailWidth: 102,
                thumbnailHeight: 181,
            },
        ]
    },
    {
        id: '22SS',
        title: '春夏新品系列',
        desc: `
將「毅力不搖」的意念轉化成「山」的圖樣，注入 CARRYON Logo ，並加入塗鴉元素，呈現全新的 Logo 概念，在服飾背面中間也保留原始 Logo，前後呼應對於堅持精神的傳遞！ 

材質採用台灣棉質製成，白色款式特別使用不透膚材質，穿著上如果流汗後不再怕太透。
        `,
        cover: '/image/sp/22SS/cover.jpg',
        banner: '/image/sp/22SS/banner.mov',
        images: [
            {
                src: '/image/sp/22SS/1.jpg',
                thumbnail: '/image/sp/22SS/1.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 181,
                
            },
            {
                src: '/image/sp/22SS/2.jpg',
                thumbnail: '/image/sp/22SS/2.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 181,
                
            },
            {
                src: '/image/sp/22SS/3.jpg',
                thumbnail: '/image/sp/22SS/3.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 181,
                
            },
            {
                src: '/image/sp/22SS/4.jpg',
                thumbnail: '/image/sp/22SS/4.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 181,
                
            },
            {
                src: '/image/sp/22SS/5.jpg',
                thumbnail: '/image/sp/22SS/5.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 181,
                
            },
            {
                src: '/image/sp/22SS/6.jpg',
                thumbnail: '/image/sp/22SS/6.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 181,
                
            },
            {
                src: '/image/sp/22SS/7.jpg',
                thumbnail: '/image/sp/22SS/7.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 181,

            },
            {
                src: '/image/sp/22SS/8.jpg',
                thumbnail: '/image/sp/22SS/8.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 181,
                
            },
        ]
    },
    
    {
        id: '21FW',
        title: '2021 冬季全新概念系列',
        desc: `
長大後，每到不同階段、角色的轉換，將面對不可避免的現實問題
各種情緒湧上心頭，焦慮、緊張、壓力隨之而來
要苟同現實，還是堅持夢想？
    
將面對現實的情緒，緊張、壓力、焦慮感等感受注入設計理念中，背後充斥著不同情緒的狀態，選用舒適優質布料構成，表層防寒、內裡刷毛保暖，且不容易起毛球。
        `,
        cover: '/image/sp/21FW/cover.jpg',
        banner: '/image/sp/21FW/banner.mp4',
        images: [
            {
                src: '/image/sp/21FW/1.jpg',
                thumbnail: '/image/sp/21FW/1.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
            {
                src: '/image/sp/21FW/2.jpg',
                thumbnail: '/image/sp/21FW/2.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
            {
                src: '/image/sp/21FW/3.jpg',
                thumbnail: '/image/sp/21FW/3.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
            {
                src: '/image/sp/21FW/4.jpg',
                thumbnail: '/image/sp/21FW/4.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
            {
                src: '/image/sp/21FW/5.jpg',
                thumbnail: '/image/sp/21FW/5.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
            {
                src: '/image/sp/21FW/6.jpg',
                thumbnail: '/image/sp/21FW/6.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
            {
                src: '/image/sp/21FW/7.jpg',
                thumbnail: '/image/sp/21FW/7.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,

            },
            {
                src: '/image/sp/21FW/8.jpg',
                thumbnail: '/image/sp/21FW/8.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
        ]
    },
    {
        id: '21SS',
        title: 'Dreams come true\n全新概念刺繡短袖',
        desc: `
前往夢想道路上，堅信著自己的信念，相信總有一天會成真！

本次 CARRYON Dreams come true 全新概念刺繡短袖，搭載 5.6 磅厚度及 17 織上等純綿材質構成，領緣採用雙層縫製手法，增加整體穿著耐久度，此款胸前的標語運用刺繡手法，完美展現全新風格面貌。
        `,
        cover: '/image/sp/21SS/cover.jpg',
        banner: '/image/sp/21SS/banner.mp4',
        images: [
            {
                src: '/image/sp/21SS/1.jpg',
                thumbnail: '/image/sp/21SS/1.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
            {
                src: '/image/sp/21SS/2.jpg',
                thumbnail: '/image/sp/21SS/2.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
            {
                src: '/image/sp/21SS/3.jpg',
                thumbnail: '/image/sp/21SS/3.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
            {
                src: '/image/sp/21SS/4.jpg',
                thumbnail: '/image/sp/21SS/4.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
            {
                src: '/image/sp/21SS/5.jpg',
                thumbnail: '/image/sp/21SS/5.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
            {
                src: '/image/sp/21SS/6.jpg',
                thumbnail: '/image/sp/21SS/6.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
            {
                src: '/image/sp/21SS/7.jpg',
                thumbnail: '/image/sp/21SS/7.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,

            },
            {
                src: '/image/sp/21SS/8.jpg',
                thumbnail: '/image/sp/21SS/8.jpg',
                thumbnailWidth: 108,
                thumbnailHeight: 108,
                
            },
        ]
    },
    {
        id: '20FW-W1',
        title: 'Logo 圓領薄長T',
        desc: `
CARRYON 秋冬 Logo 長袖單品，一起穿搭出你的堅持！

日常穿搭也是要穿出態度，時時刻刻提醒自己，堅持做喜愛的事，讓喜歡的事有價值！

本次 CARRYON Logo 圓領薄長T，沿用簡潔有力的 Logo在胸前，選用台灣製棉質長袖，打破以往版型，更加寬鬆，舒適升級！
        `,
        cover: '/image/sp/20FW-W1/cover.jpg',
        banner: '/image/sp/20FW-W1/banner.png',
        bannerType: 'image',
        images: [
            {
                src: '/image/sp/20FW-W1/1.jpg',
                thumbnail: '/image/sp/20FW-W1/1.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20FW-W1/2.jpg',
                thumbnail: '/image/sp/20FW-W1/2.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20FW-W1/3.jpg',
                thumbnail: '/image/sp/20FW-W1/3.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20FW-W1/4.jpg',
                thumbnail: '/image/sp/20FW-W1/4.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20FW-W1/5.jpg',
                thumbnail: '/image/sp/20FW-W1/5.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20FW-W1/6.jpg',
                thumbnail: '/image/sp/20FW-W1/6.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20FW-W1/7.jpg',
                thumbnail: '/image/sp/20FW-W1/7.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20FW-W1/8.jpg',
                thumbnail: '/image/sp/20FW-W1/8.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
        ]
    },
    {
        id: '20FW',
        title: 'Game On Or Game Over\n概念帽T',
        desc: `
Hey ! You Lost ! Game On Or Game Over？

面對失敗或挑戰，該用什麼方式突破？
選擇繼續奮戰或選擇就此認輸？

本季 CARRYON Game On Or Game Over 概念帽T，選用質感加倍的刷毛連帽上衣，讓你在寒冷的冬季，同時擁有保暖度及舒適度的享受，背後 Logo 採用遊戲元素的字樣，跳色的視覺設計，整體是帥到爆表！

保暖、舒適、好看，完全是冬天賴床隨手一抓就可以出門的救贖啊～
        `,
        cover: '/image/sp/20FW/cover.jpg',
        banner: '/image/sp/20FW/banner.mp4',
        images: [
            {
                src: '/image/sp/20FW/1.jpg',
                thumbnail: '/image/sp/20FW/1.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 80,
                
            },
            {
                src: '/image/sp/20FW/2.jpg',
                thumbnail: '/image/sp/20FW/2.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20FW/3.jpg',
                thumbnail: '/image/sp/20FW/3.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20FW/4.jpg',
                thumbnail: '/image/sp/20FW/4.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20FW/5.jpg',
                thumbnail: '/image/sp/20FW/5.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 80,
                
            },
            {
                src: '/image/sp/20FW/6.jpg',
                thumbnail: '/image/sp/20FW/6.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20FW/7.jpg',
                thumbnail: '/image/sp/20FW/7.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20FW/8.jpg',
                thumbnail: '/image/sp/20FW/8.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
        ]
    },
    {
        id: '20SS-W1',
        title: '刺繡 Logo 老帽',
        desc: `
小小的儀式感可以給予乏味的生活大大的能量！

每個人都有屬於自己的生活儀式感，而你的生活儀式感是什麼？
早晨的第一杯咖啡、午間的療癒吸貓時光、睡前告別一天的放空冥想，
生活中的種種小儀式，默默地在一天之中，為我們增添不少能量！
    
CARRYON 刺繡 Logo 老帽將延續品牌對於生活保有堅持的理念，使用刺繡手法將 Logo 置入老帽，可依照個人頭圍自行調節舒適的寬度，簡約設計好搭配，讓我們從明天開始！用戴上老帽的小儀式，為自己開啟展新的每一天！
        `,
        cover: '/image/sp/20SS-W1/cover.jpg',
        banner: '/image/sp/20SS-W1/banner.mp4',
        images: [
            {
                src: '/image/sp/20SS-W1/1.jpg',
                thumbnail: '/image/sp/20SS-W1/1.jpg',
                thumbnailWidth: 277,
                thumbnailHeight: 185,
                
            },
            {
                src: '/image/sp/20SS-W1/2.jpg',
                thumbnail: '/image/sp/20SS-W1/2.jpg',
                thumbnailWidth: 277,
                thumbnailHeight: 185,
                
            },
            {
                src: '/image/sp/20SS-W1/3.jpg',
                thumbnail: '/image/sp/20SS-W1/3.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 80,
                
            },
            {
                src: '/image/sp/20SS-W1/4.jpg',
                thumbnail: '/image/sp/20SS-W1/4.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 80,
                
            },
            {
                src: '/image/sp/20SS-W1/5.jpg',
                thumbnail: '/image/sp/20SS-W1/5.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20SS-W1/6.jpg',
                thumbnail: '/image/sp/20SS-W1/6.jpg',
                thumbnailWidth: 277,
                thumbnailHeight: 185,
                
            },
            {
                src: '/image/sp/20SS-W1/7.jpg',
                thumbnail: '/image/sp/20SS-W1/7.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 80,
                
            },
            {
                src: '/image/sp/20SS-W1/8.jpg',
                thumbnail: '/image/sp/20SS-W1/8.jpg',
                thumbnailWidth: 277,
                thumbnailHeight: 185,
                
            },
        ]
    },
    {
        id: '20SS',
        title: '概念 Logo 短袖',
        desc: `
夢想、現實僅一線之隔，你，為了你堅持，成為心中理想的樣子
 
CARRYON 傳遞，堅持的精神，不斷成長，不向現實屈服，勇於追逐夢想
         
你用你的堅持，努力在生活中描繪出心中的理想世界，
而我們用我們的堅持，傳遞著這些簡單且美好的執著，
將每一份珍貴的信念，注入生活，並讓它成為生活，
願那些屬於你我的熱情，永遠不被現實澆熄。
CARRYON 所傳遞的，是堅持的精神，堅持不斷成長，堅持不向現實屈服，
堅持，讓我們離夢想，更進一步！
         
A story about CARRYON 阿賢 / You_zi_xian
         
「工作是為了過活，跳舞是為了生活。」
        
來自 ODD ONES 的阿賢，為新生代街舞老師。在他的跳舞路上，比起亮眼的比賽成績，對於跳舞的熱忱以及堅持才是他持續提升自己的動力。
        
面對現實，需要為了工作而跳，一切不再是那麼的純粹。即使如此，偶爾在跳舞的時候心中感受到雀躍，或是在程度上獲得進步，那就是繼續前進的。
         
A story about CARRYON 海莉 / Haley_ting
         
「在夢想與現實之間抗爭」
         
HOLIC 潮流電商 / 舞者
         
來自輔仁大學，目前在HOLIC電商營運部門實習，在每天只有24小時下，同時兼顧學業、工作、教兒街，在這之中，需要有極大的堅持，平衡所有事情。
         
半工半讀了七年，跳舞跳了六年，
這路途上起起落落，有時跳舞耽誤到了課業、工作，甚至有時會忙碌到無法跳舞，與此同時，生活中還有大大小小的壓力不斷逼迫我成長，
我相信付出的一切，終究會有收穫的，總有一天可以成為自己理想中的樣子。
        `,
        cover: '/image/sp/20SS/cover.jpg',
        banner: '/image/sp/20SS/banner.mp4',
        images: [
            {
                src: '/image/sp/20SS/1.jpg',
                thumbnail: '/image/sp/20SS/1.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 80,
                
            },
            {
                src: '/image/sp/20SS/2.jpg',
                thumbnail: '/image/sp/20SS/2.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20SS/3.jpg',
                thumbnail: '/image/sp/20SS/3.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 179,
                
            },
            {
                src: '/image/sp/20SS/4.jpg',
                thumbnail: '/image/sp/20SS/4.jpg',
                thumbnailWidth: 179,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/20SS/5.jpg',
                thumbnail: '/image/sp/20SS/5.jpg',
                thumbnailWidth: 179,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/20SS/6.jpg',
                thumbnail: '/image/sp/20SS/6.jpg',
                thumbnailWidth: 179,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/20SS/7.jpg',
                thumbnail: '/image/sp/20SS/7.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 180,
                
            },
            {
                src: '/image/sp/20SS/8.jpg',
                thumbnail: '/image/sp/20SS/8.jpg',
                thumbnailWidth: 120,
                thumbnailHeight: 179,
                
            },
            {
                src: '/image/sp/20SS/9.jpg',
                thumbnail: '/image/sp/20SS/9.jpg',
                thumbnailWidth: 179,
                thumbnailHeight: 120,
                
            },
            {
                src: '/image/sp/20SS/10.jpg',
                thumbnail: '/image/sp/20SS/10.jpg',
                thumbnailWidth: 179,
                thumbnailHeight: 120,
                
            },
        ]
    },
    // {
    //     id: '',
    //     title: '',
    //     desc: [],
    //     cover: '',
    //     images: []
    // },
];

export default list;
