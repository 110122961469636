/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import styles from './Footer.module.scss';
// import utility from '../utility';

const Footer = () => {

    return (
        <footer className={styles.wrap}>
            <div className={styles.row}>
                <div className={styles.copyright}>
                    Copyright ©&nbsp;{new Date().getFullYear()}&nbsp;CARRYON
                    <div className={styles.sm}>
                        <a href="https://www.instagram.com/carryon_clothing_/?utm_source=carryon&utm_medium=footer" target="_blank" rel="noreferrer noopener" aria-label="carryon instagram">
                            <i className="icon icon-instagram"></i>
                        </a>
                        <a href="https://www.facebook.com/CARRYON.clothing/?utm_source=carryon&utm_medium=footer" target="_blank" rel="noreferrer noopener" aria-label="carryon facebook">
                            <i className="icon icon-facebook"></i>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );

};
export default Footer;
