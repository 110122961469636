import React from 'react';

import { Link } from 'react-router-dom';

// import { useMediaQuery } from 'react-responsive';


import utility from '../../utility';


import styles from './Products.module.scss';

import prods from '../../productList';



const Products = ({ index = 0 }) => {

    const { source, name, subTitle, list } = prods[index];
    const newList = [].concat(list.filter((data) => data.inStock)).concat(list.filter((data) => !data.inStock));

    return (
        <div className={styles.wrap}>
            <h2>
                🔥 {name} 🔥
                {
                    subTitle
                        ? <span className={styles.subTitle}>{subTitle}</span>
                        : null
                }
            </h2>
            <div className={utility.mixClasses(styles.row, styles.prodWrap)} >
                {
                    source === 'aws'
                        ? (
                            newList
                                .map(({ sku, caption, image, series, spec, price, inStock, specialOffer, comingSoon }) => (
                                    <div key={sku} className={styles.prod}>
                                        <Link to={`/product/${sku}${utility.const.product.pathOperator}${caption.replace(utility.const.product.pathRegExp, utility.const.product.pathReplaceChar)}`} className={utility.mixClasses('', '')}>
                                            <div className={styles.imgWrap} >
                                                <img src={encodeURI(`https://assets.carryon-clothing.com/${series}/product/${sku}/1.png`)} alt={caption} width="200" height="200" loading="lazy" />
                                                {
                                                    !inStock
                                                        ? <div className={styles.stock}>售完</div>
                                                        : null
                                                }
                                                {
                                                    comingSoon
                                                        ? <div className={styles.comingSoon}>Coming Soon</div>
                                                        : null
                                                }
                                            </div>
                                            <div className={styles.caption}>{caption}</div>
                                            <div className={styles.sub}>
                                                {/* <div className={styles.spec}>{spec}</div> */}
                                                <div className={utility.mixClasses('jso-price', styles.price)}>
                                                    <span className={specialOffer && styles.specialOfferLineThrough}>
                                                        {utility.priceWithCommas(price)}
                                                    </span>
                                                    {
                                                        specialOffer && (
                                                            <span className={styles.specialOffer}>
                                                                {utility.priceWithCommas(specialOffer)}
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                        )
                        : (
                            newList
                                .map(({ sku, caption, image, series, spec, price, inStock, specialOffer }) => (
                                    <div key={sku} className={styles.prod}>
                                        <Link to={`/product/${sku}${utility.const.product.pathOperator}${caption.replace(utility.const.product.pathRegExp, utility.const.product.pathReplaceChar)}`} className={utility.mixClasses('', '')}>
                                            <div className={styles.imgWrap} >
                                                <img src={`/image/product/${series}/main/${sku}/1.png`} alt={caption} width="200" height="200" loading="lazy" />
                                                {
                                                    !inStock
                                                        ? <div className={styles.stock}>售完</div>
                                                        : null
                                                }
                                            </div>
                                            <div className={styles.caption}>{caption}</div>
                                            <div className={styles.sub}>
                                                {/* <div className={styles.spec}>{spec}</div> */}
                                                <div className={utility.mixClasses('jso-price', styles.price)}>
                                                    <span className={specialOffer && styles.specialOfferLineThrough}>
                                                        {utility.priceWithCommas(price)}
                                                    </span>
                                                    {
                                                        specialOffer && (
                                                            <span className={styles.specialOffer}>
                                                                {utility.priceWithCommas(specialOffer)}
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                        )
                }
            </div>
        </div>
    )

}

export default React.memo(Products);
