import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

function MetaTag({
    url, title, description, image, ldJson, robotReject
}) {

    const siteName = 'CARRYON CLOTHING 街頭潮流服飾';
    const pageTitle = title ? `${title} - ${siteName}` : siteName;

    return (
        <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={description} />

            <meta property="og:title" content={pageTitle} />
            <meta property="og:url" content={url} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />

            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={description} />

            <link rel="canonical" href={url} />

            {
                robotReject && <meta name="robots" content="none" />
            }

            {/* json ld 區塊 */}
            {
                ldJson
                    && (typeof ldJson === 'object')
                    && !Array.isArray(ldJson)
                    && ldJson !== null
                    ? (
                        <script type="application/ld+json">
                            {JSON.stringify(ldJson)}
                        </script>
                    )
                    : null
            }

        </Helmet>
    );

}

MetaTag.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    ldJson: PropTypes.shape({
        '@context': PropTypes.string.isRequired
    }),
    robotReject: PropTypes.bool
};

const {
    url, title, description, image
} = {
    url: 'https://www.carryon-clothing.com/',
    title: '',
    description: '2020 年創立，CARRYON 傳達堅持的精神、不斷成長不向現實屈服，呼應品牌理念「你，為了你堅持」，銷售多款自創街頭潮流服飾，限量發售立即選購！',
    image: 'https://www.carryon-clothing.com/image/carryon_logo-og.jpg'
};

MetaTag.defaultProps = {
    url,
    title,
    description,
    image,
    ldJson: null,
    robotReject: false
};

export default React.memo(MetaTag);
