import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Index from './pages/Index';
import Product from './pages/Product';
import Special from './pages/Special';
import AboutUs from './pages/AboutUs';
import BuyingGuide from './pages/BuyingGuide';
import ShippingAndReturns from './pages/ShippingAndReturns';
import PrivacyAndTerms from './pages/PrivacyAndTerms';

import NotFound from './pages/NotFound';

function Router() {

    return (
        <Routes>
            <Route exact path="/" element={<Index />} />
            {/* <Route path="/aaa" element={'aaa'} /> */}
            <Route path="/product/:slug" element={<Product />} />
            <Route path="/product" element={<Index />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/buying-guide" element={<BuyingGuide />} />
            <Route path="/shipping-and-returns" element={<ShippingAndReturns />} />
            <Route path="/privacy-and-terms" element={<PrivacyAndTerms />} />
            <Route path="/special/:id" element={<Special />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );

}

export default React.memo(Router);
