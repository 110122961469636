import React from 'react';
import { useParams } from 'react-router-dom';
import Gallery from 'react-grid-gallery';
// import { nanoid } from 'nanoid';
import ReactPlayer from 'react-player';

import MetaTag from '../components/MetaTag';


import NotFound from './NotFound';

import Layout from '../components/Layout';
import utility from '../utility'
import styles from './Special.module.scss';

import list from '../specialList';


const Special = () => {

    const { id } = useParams();
    const sp = list.find((item) => item.id === id);

    if (sp.source === 'aws') {

        sp.images = Array.apply(null, Array(sp.imageLength)).map((v, idx) => ( {
            src: `https://assets.carryon-clothing.com/${sp.series}/story/${idx + 1}.png`,
            thumbnail: `https://assets.carryon-clothing.com/${sp.series}/story/${idx + 1}.png`,
        }))

    }

    console.log(sp.images);

    try {

        return (
            <>
                <MetaTag
                    title={id}
                    url={`https://www.carryon-clothing.com/special/${id}`}
                />
                <Layout>
                    <section className={styles.wrap}>
                        {/* <div className={styles.row}> */}
                        <h1><span>{id}</span>{sp.title}</h1>
                        {
                            sp.bannerType === 'image'
                                ? (
                                    <picture>
                                        <img src={sp.banner} alt={sp.title} width="600" height="600" />
                                    </picture>
                                )
                                : (
                                    <div className={styles.videoWrap} title={sp.title} aria-label={sp.title}>
                                        <ReactPlayer
                                            width="100%"
                                            height="100%"
                                            playing
                                            autoPlay
                                            muted
                                            playsinline
                                            loop
                                            url={sp.banner}
                                        />
                                    </div>
                                )
                        }
                        {/* </div> */}
                    </section>
                    <section className={styles.wrap}>
                        <div className={styles.row}>
                            <p className={styles.desc}>
                                {sp.desc}
                            </p>
                        </div>
                    </section>
                    <section className={utility.mixClasses(styles.wrap, styles.bg)}>
                        <div className={styles.row}>
                            <Gallery
                                images={sp.images}
                            // enableImageSelection={false}
                            // enableLightbox={true}
                            // backdropClosesModal
                            />
                        </div>
                    </section>
                </Layout>
            </>
        )

    }
    catch (e) {

        return (
            <NotFound />
        )

    }

};
export default React.memo(Special);
