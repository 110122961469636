import React from 'react';

import MetaTag from '../components/MetaTag';


import Layout from '../components/Layout';
// import Animate from '../components/common/Animate';

import styles from './AboutUs.module.scss';

const AboutUs = () => (
    <Layout>
        <MetaTag
            title="關於我們"
            url="https://www.carryon-clothing.com/about-us"
        />
        <div className={styles.wrap}>
            <div className={styles.row}>
                <h1>關於我們</h1>
                <img src="/image/sub/first.webp" alt="2021 CARRYON hoodie 圖輯" loading="lazy" />
                <h2>CARRYON CLOTHING 街頭潮流服飾</h2>
                <p>2020 年創立，CARRYON 傳達堅持的精神、不斷成長不向現實屈服，呼應品牌理念「你，為了你堅持」，銷售多款自創街頭潮流服飾，每款限量發售！</p>
                <p>每款單品結合街頭元素設計，不斷追求高品質的質料及做工，本店未來將販售多款潮流單品，與嚴選選物品牌合作，請敬請期待。</p>
                <p>
                    若您有任何問題，歡迎與我們聯繫：
                    <br />
                    <a href="https://www.instagram.com/carryon_clothing_/?utm_source=carryon&utm_medium=about_us" target="_blank" rel="noreferrer noopener">官方 instagram</a>
                    、
                    <a href="https://www.facebook.com/CARRYON.clothing/?utm_source=carryon&utm_medium=about_us" target="_blank" rel="noreferrer noopener">官方 facebook</a>
                </p>
            </div>
        </div>
    </Layout>
);

export default AboutUs;
