/* eslint-disable import/no-anonymous-default-export */
// import axios from 'axios';

// import mockApi from '../__mock__/api';

export default {
    mixClasses: (...props) => [...props].reduce((prev, cur) => (cur ? `${prev.trim()} ${cur}` : prev), '').trim(),
    getCookie: (cname) => {

        const name = `${cname}=`,
            ca = document.cookie.split(';'),
            l = ca.length;

        for (let i = 0; i < l; i++) {

            let c = ca[i];

            while (c.charAt(0) === ' ') {

                c = c.substring(1);

            }

            if (c.indexOf(name) === 0) {

                return c.substring(name.length, c.length);

            }

        }

        return '';

    },
    setCookie: (cname, cValue, exdays = 1, domain, path = '/') => {

        const date = new Date(),
            pathStr = `path=${path}`;

        let domainStr = '';

        date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));

        const expires = `expires=${date.toUTCString()}; `;

        if (domain) {

            domainStr = `domain=${domain}; `;

        }

        document.cookie = `${cname}=${cValue};${expires}${domainStr}${pathStr}`;

    },
    priceWithCommas: (price) => price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),

    // fetchData: async (options) => {

    //     let data;

    //     try {

    //         const url = options.url.indexOf('//') !== -1
    //             ? options.url
    //             : `//${process.env.REACT_APP_API_HOST}${options.url}`;

    //         const res = await axios({
    //             method: 'get',
    //             ...options,
    //             url
    //         });

    //         data = res.data.result ?? res.data;

    //     }
    //     catch (e) {

    //         data = mockApi[options.url.split('?')[0]];

    //     }

    //     return data;

    // }
};
