import React from 'react';
import PropTypes from 'prop-types';

import { Carousel } from 'react-responsive-carousel';



import utility from '../../utility';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import 'video-react/dist/video-react.css'; 

import styles from './Carousel.module.scss';





const Banner = ({ img, caption }) => {

    return (
        <div title={caption} aria-label={caption}>
            <picture>
                <source srcSet={img} media={utility.const.mediaBreakpoint.sm.query} />
                <img src={img} alt={caption} loading="lazy" />
            </picture>
        </div>
    );

}


Banner.propTypes = {
    img: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    // data: PropTypes.shape({
    //     link: PropTypes.string,
    //     imgUrlMobile: PropTypes.string.isRequired,
    //     imgUrlDesktop: PropTypes.string.isRequired,
    //     caption: PropTypes.string,
    // }).isRequired
};


const BannerCarousel = ({ imgList, caption }) => {

    return (
        <div className={styles.wrap}>
            <div className={styles.row}>
                {
                    imgList.length === 1
                        ? (
                            <Banner img={imgList[0]} caption={caption} />
                        )
                        : (
                            <Carousel
                                showThumbs={true}
                                showStatus={false}
                                showArrows={false}
                                infiniteLoop
                                autoPlay
                                interval="5000"
                            >
                                {
                                    imgList.map((imgSrc) => (
                                        <img key={imgSrc} src={imgSrc} alt={caption} loading="lazy" />
                                    ))
                                }
                            </Carousel>
                        )
                }

            </div>
        </div>
    );

};

export default React.memo(BannerCarousel);
