import React from 'react';

import MetaTag from '../components/MetaTag';

import Layout from '../components/Layout';
// import Animate from '../components/common/Animate';

import styles from './ShippingAndReturns.module.scss';

const ShippingAndReturns = () => (
    <Layout>
        <MetaTag
            title="隱私及條款"
            url="https://www.carryon-clothing.com/privacy-and-terms"
        />
        <div className={styles.wrap}>
            <div className={styles.row}>
                <h1>隱私及條款</h1>
                <p>CARRYON CLOTHING 街頭潮流服飾 及關係企業及其合作對象，於全世界並於合理之範圍內蒐集、處理、保存、傳遞及使用會員資料，以提供使用者其他資訊或服務、或作成會員統計資料、或進行關於網路行為之調查或研究，或為任何之合法使用，或提供其他服務，並維護會員之隱私權。</p>
                <p>當您進入後即視為本網站會員時，即表示除了同意遵守本服務條款外，還同意接受本公司對會員規範（包括但不限於會員條款、公告及注意事項、使用說明以及隱私權政策等）及相關法令規定之拘束，本公司有權不經通知逕行增加、變更或取消本服務中相關系統、功能以及公告之全部或一部份之權利，會員條款異動及其公告說明請您另於本網站查閱。</p>
                <p>我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。</p>
                <p>您於任何修改或變更後繼續使用本網站服務，即視為您已閱讀、瞭解並同意接受該等修改或變更，若您不同意本政策的內容，您應立即停止使用。</p>
                <h2>使用目的</h2>
                <p>CARRYON CLOTHING 街頭潮流服飾得為改善服務內容、商業與技術資訊、會員管理、行銷、資料與資料庫管理、會員使用服務據統計或進行關於網路行為之調查、研究與分析、其他合於營業登記項目或章程鎖定業務之需要，或係依法令之規定要求等目的，蒐集、處理、保存、傳遞利用、國際傳輸會員資料，以及與前述目的範圍內正當合理關聯利用會員所提供之個人資料。</p>
                <h2>蒐集方式及利用範圍</h2>
                <p>當會員進入使用 CARRYON CLOTHING 街頭潮流服飾官方網站時，CARRYON CLOTHING 街頭潮流服飾 基於服務之提供或行銷，將會請會員填寫該服務所需之相關資料（例：訂購姓名、訂購電話、匯款資訊或基於該服務需要），而取得會員之個人資料，CARRYON CLOTHING 街頭潮流服飾僅將該資料使用在該服務。</p>
                <h2>使用期間及區域</h2>
                <p>會員所提供之個人資料，CARRYON CLOTHING 街頭潮流服飾將於全世界使用，並為提供會員完善之服務及品質，CARRYON CLOTHING 街頭潮流服飾將在法令許可或會員書面同意下，將會員所提供之個人資料提供予 CARRYON CLOTHING 街頭潮流服飾所配合、合作之廠商或關係企業，以提供會員所需之服務、行銷及相關訊息及服務。</p>
                <h2>個人資料分類及項目</h2>
                <p>CARRYON CLOTHING 街頭潮流服飾於網站內所提供之服務需要，需請會員提供其個人資料，其資料種類包括基本資料、帳務資料，分類標準如下：
                    <br />
                    （1）購買資料：包括姓名、電話、地址、匯款帳號等
                    <br />
                    會員所提供之個人資料均被嚴密地保存在 CARRYON CLOTHING 街頭潮流服飾之資料庫系統中，任一第三人、配合、合作之廠商或關係企業均需在 CARRYON CLOTHING 街頭潮流服飾之授權或委託管理規範下才可進行該資料之取得與使用。
                </p>
                <h2>退出方式</h2>
                <p>CARRYON CLOTHING 街頭潮流服飾將依據現行法令，在CARRYON CLOTHING 街頭潮流服飾配合、合作之廠商或關係企業間交互運用與揭露，若會員不願意將其所提供之個人資料提供予 CARRYON CLOTHING 街頭潮流服飾配合、合作之廠商或關係企業繼續使用，會員得以訊息告知 CARRYON CLOTHING 街頭潮流服飾，CARRYON CLOTHING 街頭潮流服飾將停止使用會員之個人資料。會員得自由選擇提供個人資料之程度，惟若因會員所提供之個人資料不足、無法辨識會員身分或有誤時，CARRYON CLOTHING 街頭潮流服飾則可能無法提供完整之服務。</p>
                <h2>業務終止</h2>
                <p>CARRYON CLOTHING 街頭潮流服飾因歇業、解散而無承受機關，或所營事業營業項目變更而與原蒐集目的不符，或會員以書面要求終止、解除會員帳號或其他事由無需蒐集、利用、保存會員所提供之個人資料，CARRYON CLOTHING 街頭潮流服飾將於前述事項發生後 30 日內刪除會員所提供之個人資料。</p>
            </div>
        </div>
    </Layout>
);

export default ShippingAndReturns;
