export const bannerType = {
    image: 0,
    youtube: 1,
    video: 2
}

export const bannerList = [
      {
        bannerType: bannerType.video,
        src: 'https://assets.carryon-clothing.com/24SS Chillax/banner/1.mp4',
        caption: ''
    },
    // {
    //     bannerType: bannerType.image,
    //     imgUrlMobile: 'https://assets.carryon-clothing.com/24SSLAB 2nd Release/banner/1.png',
    //     imgUrlDesktop: 'https://assets.carryon-clothing.com/24SSLAB 2nd Release/banner/1.png',
    //     caption: ''
    // },
    // {
    //     bannerType: bannerType.video,
    //     src: 'https://assets.carryon-clothing.com/23FW/banner/23FW.mp4',
    //     caption: ''
    // },
    // {
    //     link: '',
    //     caption: '',
    //     imgUrlMobile: '/image/banner/2022-1.jpeg',
    //     imgUrlDesktop: '/image/banner/2022-1.jpeg',
    //     captionColor: '',
    //     contentColor: '',
    //     buttonText: null
    // },
    // {
    //     link: '',
    //     caption: '',
    //     imgUrlMobile: '/image/banner/2022-1-2.jpg',
    //     imgUrlDesktop: '/image/banner/2022-1-2.jpg',
    //     captionColor: '',
    //     contentColor: '',
    //     buttonText: null
    // },
    // {
    //     bannerType: bannerType.youtube,
    //     videoID: 'kQBJrtuK8lY',
    //     caption: '',
    //     ratio: 1
    // },
    // {
    //     link: '',
    //     caption: '',
    //     imgUrlMobile: 'https://via.placeholder.com/450x300',
    //     imgUrlDesktop: 'https://via.placeholder.com/1920x500',
    //     captionColor: '',
    //     contentColor: '',
    //     buttonText: null
    // },
    // {
    //     link: '',
    //     caption: '',
    //     imgUrlMobile: 'https://via.placeholder.com/450x300',
    //     imgUrlDesktop: 'https://via.placeholder.com/1920x500',
    //     captionColor: '',
    //     contentColor: '',
    //     buttonText: null
    // },
    // {
    //     link: '',
    //     caption: '',
    //     imgUrlMobile: 'https://via.placeholder.com/450x300',
    //     imgUrlDesktop: 'https://via.placeholder.com/1920x500',
    //     captionColor: '',
    //     contentColor: '',
    //     buttonText: null
    // }
];
