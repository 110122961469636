import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Routes from './Routes'

import './styles/global.scss';


function App() {

    const { pathname } = useLocation();

    useEffect(() => {

        window.scrollTo(0, 0);

    }, [pathname]);

    return (
        <Routes></Routes>
    );

}

export default React.memo(App);
