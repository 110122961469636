import React, { useMemo } from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';

import styles from './SlideUp.module.scss';

function SlideUp({ children, delayOffset = 0 }) {

    const props = useSpring({
        to: { transform: 'translateY(-80px)' },
        from: { transform: 'translateY(0)' }
    });

    const delay = useMemo(() => Math.random() * 300 + 100 + ((delayOffset > 6 ? 300 : delayOffset * 50)), [delayOffset]);

    const [ref, isInView] = useInView({
        triggerOnce: true,
        trackVisibility: true,
        delay,
        // rootMargin: '-10px'
    });

    return (
        <animated.div ref={ref} className={styles.wrap} style={isInView ? props : null}>
            {children}
        </animated.div>
    );

}


export default React.memo(SlideUp);
